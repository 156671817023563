var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { selectWith } from '../../store/query/selectors';
import { selectWith as select } from '../../store/selections/selectors';
import { toggleItemInfluence } from '../../store/selections/actions';
import { setFilterText } from '../../store/query/actions';
import { connect } from 'react-redux';
import SettingsIcon from '../SettingIcon/SettingsIcon';
import { ItemInfluence } from '../../store/selections/types';
var styles = require("./component.css");
;
var timeout = undefined;
var func = undefined;
var throttle = function (fn, time) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        func = fn.bind.apply(fn, __spreadArrays([this], args));
        var functionCall = function () {
            timeout = undefined;
            func();
        };
        if (!timeout) {
            timeout = setTimeout(functionCall, time);
        }
    };
};
var ToolBar = function (_a) {
    var itemLevel = _a.itemLevel, itemInfluence = _a.itemInfluence, setFilterText = _a.setFilterText, toggleItemInfluence = _a.toggleItemInfluence;
    var _b = React.useState(''), filterValue = _b[0], setFilterValue = _b[1];
    var updateFilter = throttle(function (value) { return setFilterText(value); }, 150);
    var onChange = function (e) {
        setFilterValue(e.currentTarget.value);
        updateFilter(e.currentTarget.value);
    };
    var onChangeBase = function (clickedInfluence) {
        toggleItemInfluence(clickedInfluence);
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.base_requirements },
            React.createElement("div", { className: styles.itembase }, "ILVL" + itemLevel,
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.SHAPER) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.SHAPER); }, src: "https://web.poecdn.com/image/item/popup/shaper-symbol.png?1568172500065", width: "30", height: "30", alt: "Shaper Base" }),
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.ELDER) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.ELDER); }, src: "https://web.poecdn.com/image/item/popup/elder-symbol.png?1568172500061", width: "30", height: "30", alt: "Elder Base" }),
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.CRUSADER) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.CRUSADER); }, src: "https://web.poecdn.com/image/item/popup/crusader-symbol.png?1575932522880", width: "30", height: "30", alt: "Crusader Base" }),
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.BASILISK) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.BASILISK); }, src: "https://web.poecdn.com/image/item/popup/hunter-symbol.png?1575932522880", width: "30", height: "30", alt: "Hunter Influence" }),
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.EYRIE) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.EYRIE); }, src: "https://web.poecdn.com/image/item/popup/redeemer-symbol.png?1575932522880", width: "30", height: "30", alt: "Redeemer Influence" }),
                React.createElement("img", { className: itemInfluence.includes(ItemInfluence.ADJUDICATOR) ? '' : styles.disabled, onClick: function () { return onChangeBase(ItemInfluence.ADJUDICATOR); }, src: "https://web.poecdn.com/image/item/popup/warlord-symbol.png?1575932522880", width: "30", height: "30", alt: "Warlord Influence" }))),
        React.createElement("input", { type: "text", placeholder: "filter...", onChange: onChange, value: filterValue }),
        React.createElement(SettingsIcon, null)));
};
var mapStateToProps = function (state) { return ({
    itemLevel: selectWith(state.old).getMaxItemLevel(),
    itemInfluence: select(state).getItemInfluence(),
}); };
export default connect(mapStateToProps, { setFilterText: setFilterText, toggleItemInfluence: toggleItemInfluence })(ToolBar);
