export var ADD_SELECTED_MOD = "ADD_SELECTED_MOD";
export var REMOVE_SELECTED_MOD = "REMOVE_SELECTED_MOD";
export var SET_HIGHLIGHT_GROUP = "SET_HIGHLIGHT_GROUP";
export var SET_EXTRA_TAGS = "SET_EXTRA_TAGS";
export var SET_ITEM_MODS = "SET_ITEM_MODS";
export var SET_FOSSIL_MAPPINGS = "SET_FOSSIL_MAPPINGS";
export var SET_ESSENCE_MAPPINGS = "SET_ESSENCE_MAPPINGS";
export var SET_ESSENCE_DATA = "SET_ESSENCE_DATA";
export var SET_CRAFTING_BENCH_MAPPINGS = "SET_CRAFTING_BENCH_MAPPINGS";
export var SET_CURRENCIES = "SET_CURRENCIES";
export var SET_ELDER_TAG = "SET_ELDER_TAG";
export var SET_SHAPER_TAG = "SET_SHAPER_TAG";
export var SET_FILTER_TEXT = "SET_FILTER_TEXT";
export var SET_LUCKINESS = "SET_LUCKINESS";
export var SET_ITEMS = "SET_ITEMS";
export var ADD_TOAST_MESSAGE = "ADD_TOAST_MESSAGE";
export var REMOVE_TOAST_MESSAGE = "REMOVE_TOAST_MESSAGE";
export var TOGGLE_TOOLBAR = "TOGGLE_TOOLBAR";
export var SET_IMPLICIT_MODS = "SET_IMPLICIT_MODS";
