var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ModDomain } from '../../../types';
import { Map } from 'immutable';
import { setPossibleItemMods } from '../selections/actions';
import difference from 'lodash/difference';
import { SET_SELECTED_ITEM, TOGGLE_ITEM_INFLUENCE } from '../selections/types';
import { getItemTags } from '../../common';
var getItemDomain = function (tags) {
    if (tags.includes("abyss_jewel")) {
        return ModDomain.abyss_jewel;
    }
    else if (tags.includes("jewel")) {
        return ModDomain.jewel;
    }
    return ModDomain.item;
};
// TODO: extract SET_FILTER_TEXT and SET_EXTRA_TAGS for performance!
var triggeringActions = [SET_SELECTED_ITEM, TOGGLE_ITEM_INFLUENCE];
export default (function (store) { return function (next) { return function (action) {
    var _a;
    var result = next(action);
    var state = store.getState();
    if (!triggeringActions.includes(action.type) || !state.selections.selectedItem) {
        return result;
    }
    var explicits = state.data.explicits;
    var craftingModTagsByModId = state.old.craftingModTagsByModId;
    var item = state.selections.selectedItem;
    var extraTags = state.old.extraTags || [];
    var implicitIds = (state.selections.selectedImplicits || [])[0];
    var implicitMods = state.data.implicits.get(getItemDomain(item.tags));
    var itemImplicitMods = implicitIds.map(function (modId) { return implicitMods.get(modId); }).filter(function (it) { return it !== undefined; });
    var implictTags = itemImplicitMods.reduce(function (acc, mod) { return acc.concat(mod.tags); }, []);
    var tags = getItemTags(item, state.selections.itemInfluence).concat(implictTags);
    var addedDelveModsIds = state.selections.fossils.reduce(function (modIds, fossil) { return modIds.concat(fossil.addedMods); }, []);
    var modGroups = Map((_a = {
            'BASE': explicits.get(getItemDomain(tags))
        },
        _a[ModDomain.shaper] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.elder] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.crusader] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.eyrie] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.basilisk] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.adjudicator] = explicits.get(getItemDomain(tags)),
        _a[ModDomain.crafted] = explicits.get(ModDomain.crafted),
        _a[ModDomain.delve] = explicits.get(ModDomain.delve),
        _a[ModDomain.essence] = explicits.get(ModDomain.item),
        _a[ModDomain.implicits] = explicits.get(ModDomain.implicits),
        _a));
    var filtered = modGroups.filter(function (it) { return it; }).mapEntries(function (_a) {
        var domain = _a[0], modsById = _a[1];
        var filtered = modsById.map(function (mod) {
            var weightModifier = mod.generationWeights
                .filter(function (w) { return ['default'].concat(extraTags).includes(w.tag); })[0] || { tag: 'unmodified', weight: 100 };
            var includeDelve = ModDomain.delve.toString() !== domain || addedDelveModsIds.includes(mod.id) ? 1 : 0;
            var weightMultiplier = weightModifier.weight / 100;
            var baseWeight = mod.spawnWeights.filter(function (w) { return tags.includes(w.tag); })[0] || { tag: 'unspawnable', weight: 0 };
            var modifiedWeight = baseWeight && __assign(__assign({}, baseWeight), { weight: baseWeight.weight * weightMultiplier * includeDelve });
            return __assign(__assign({}, mod), { baseWeight: baseWeight, modifiedWeight: modifiedWeight });
        })
            .filter(function (mod) {
            if (domain == ModDomain.essence) {
                var essenceMappings = (state.old.essenceMappingByModId[mod.id] || []).map(function (mapping) { return mapping.tags; });
                var foundMappings = essenceMappings.filter(function (tags) { return difference(tags, item.tags).length == 0; });
                return foundMappings.length > 0;
            }
            if (domain == ModDomain.elder) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagElder; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.shaper) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagShaper; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.crusader) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagCrusader; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.eyrie) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagEyrie; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.basilisk) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagBasilisk; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.adjudicator) {
                var weight = mod.spawnWeights.find(function (weight) { return weight.tag == item.tagAdjudicator; });
                return weight && weight.weight > 0;
            }
            if (domain == ModDomain.crafted) {
                var modMappings = craftingModTagsByModId[mod.id] || [];
                var foundMappings = modMappings.filter(function (t) { return difference(t, tags).length == 0; });
                return foundMappings.length > 0;
            }
            else {
                var weight = mod.baseWeight && mod.baseWeight.weight || 0;
                return weight > 0;
            }
        });
        return [domain, filtered];
    });
    var itemModTypeKeys = filtered.map(function (modsById) {
        return modsById
            .groupBy(function (mod) { return mod.type; })
            .toMap()
            .map(function (affix) {
            return affix
                .sortBy(function (mod) { return mod.id; })
                .groupBy(function (om) { return om.modTypeKey; })
                .map(function (tiers) { return tiers.sortBy(function (tier) { return tier.ilvl; }).valueSeq().toArray(); })
                .toOrderedMap();
        });
    });
    //setTimeout(() => store.dispatch(setPossibleItemMods(itemModTypeKeys)), 0)
    store.dispatch(setPossibleItemMods(itemModTypeKeys));
    return result;
}; }; });
