import * as React from "react";
import { selectWith } from "../../store/selections/selectors";
import { connect } from "react-redux";
import SelectedAffixGroup from "./SelectedAffixGroup/SelectedAffixGroup";
import CraftingCost from "../CraftingCost/CraftingCost";
var styles = require("./component.css");
var SelectedMods = function (_a) {
    var item = _a.item, implicits = _a.implicits, prefixes = _a.prefixes, suffixes = _a.suffixes;
    var _b = React.useState(true), show = _b[0], setShow = _b[1];
    var hasSelectedMods = prefixes.find(function (x) { return x.length; }) || suffixes.find(function (x) { return x.length; });
    var flatImplicits = implicits.reduce(function (arr, group) { return arr.concat(group); }, []);
    var isCorrupted = !!flatImplicits.find(function (mod) { return !item.implicitMods.includes(mod.id); });
    var canBeVisible = hasSelectedMods || isCorrupted;
    var wrappingClass = canBeVisible && show ? "toolbar-position active" : "toolbar-position";
    var getImplicitComponent = function () {
        return flatImplicits.length === 0 ? false : (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "twelve columns" },
                React.createElement("div", { className: "affix_type_header bellow" }, isCorrupted ? 'corrupted' : 'implicit'),
                React.createElement("div", { className: "mod-tier-container" },
                    React.createElement(SelectedAffixGroup, { groups: implicits, hasGroups: false })))));
    };
    var emptyExplicitGroup = React.createElement("div", { className: "six columns" }, "\u00A0");
    var listsIsEmpty = function (list) { return list.filter(function (group) { return group.length > 0; }).length === 0; };
    var explicitGroup = function (title, mods) { return listsIsEmpty(mods) ? emptyExplicitGroup : (React.createElement("div", { className: "six columns" },
        React.createElement("div", { className: "affix_type_header bellow" }, title),
        React.createElement("div", { className: "mod-tier-container" },
            React.createElement(SelectedAffixGroup, { groups: mods, hasGroups: true })))); };
    var toggle = !canBeVisible ? false : (React.createElement("div", { className: styles.toggle, onClick: function () { return setShow(!show); } }, show ? 'hide' : 'selected mods'));
    return (React.createElement("div", null,
        React.createElement("div", { className: "row" },
            React.createElement(CraftingCost, null)),
        getImplicitComponent(),
        React.createElement("div", { className: "row" },
            explicitGroup('prefix', prefixes),
            explicitGroup('suffix', suffixes))));
};
var mapStateToProps = function (state) { return ({
    item: selectWith(state).getSelectedItem(),
    implicits: selectWith(state).getSelectedImplicits(),
    prefixes: selectWith(state).getSelectedPrefixes(),
    suffixes: selectWith(state).getSelectedSuffixes(),
}); };
export default connect(mapStateToProps)(SelectedMods);
