var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { ModDomain } from "../../../types";
import { connect } from 'react-redux';
import { selectWith as select } from '../../store/selections/selectors';
import CurrencyStack from '../CurrencyStack/CurrencyStack';
import CraftingCosts from '../../CraftingCosts';
import { Map } from 'immutable';
var styles = require("./component.css");
;
var getGroupWeights = function (mods) {
    return mods.reduce(function (acc, tiers) {
        var modWeight = tiers.reduce(function (modWights, mod) {
            return {
                id: mod.group,
                weight: modWights.weight + (mod.modifiedWeight || { weight: 0 }).weight,
            };
        }, { weight: 0 });
        var previousWeight = (acc.find(function (option) { return option.id == modWeight.id; }) || { weight: 0 }).weight;
        var groupWeight = {
            id: modWeight.id,
            weight: previousWeight + modWeight.weight,
        };
        return __spreadArrays(acc.filter(function (it) { return it.id != modWeight.id; }), [groupWeight]);
    }, [])
        .filter(function (w) { return w.weight > 0; });
};
var getItemGroupWeights = function (itemMods, addedFossilMods) {
    var basePrefix = itemMods.getIn(['BASE', 'prefix'], Map());
    var baseSuffix = itemMods.getIn(['BASE', 'suffix'], Map());
    var delvePrefix = itemMods.getIn([ModDomain.delve, 'prefix'], Map());
    var delveSuffix = itemMods.getIn([ModDomain.delve, 'suffix'], Map());
    var allPossiblePrefixes = delvePrefix.mergeDeep(basePrefix);
    var allPossibleSuffixes = delveSuffix.mergeDeep(baseSuffix);
    return {
        prefix: getGroupWeights(allPossiblePrefixes),
        suffix: getGroupWeights(allPossibleSuffixes),
    };
};
var CraftingCost = function (_a) {
    var possibleItemMods = _a.possibleItemMods, selectedImplicitGroups = _a.selectedImplicitGroups, selectedPrefixGroups = _a.selectedPrefixGroups, selectedSuffixGroups = _a.selectedSuffixGroups, selectedWeightPerGroup = _a.selectedWeightPerGroup, addedDelveModIds = _a.addedDelveModIds, selectedWeightPerImplicitGroup = _a.selectedWeightPerImplicitGroup;
    var selectedGroups = selectedPrefixGroups.concat(selectedSuffixGroups);
    if (selectedGroups.length == 0 && selectedImplicitGroups.length == 0) {
        return React.createElement(React.Fragment, null);
    }
    var itemGroupWeights = getItemGroupWeights(possibleItemMods, addedDelveModIds);
    var getCurrency = function (index, value) {
        return value == 0 || value === Number.POSITIVE_INFINITY ? false : React.createElement(React.Fragment, null,
            React.createElement(CurrencyStack, { key: 'aug-cost', currencyId: index }),
            Math.ceil(value));
    };
    var chaosSpamCost = CraftingCosts.oddsChaosSpam(selectedGroups, itemGroupWeights, selectedWeightPerGroup);
    var altRegalCost = {
        alterations: 0,
        augmentations: 0
    };
    if (selectedPrefixGroups.length <= 1 && selectedSuffixGroups.length <= 1) {
        altRegalCost = CraftingCosts.altAugCost(selectedPrefixGroups.find(function (it) { return it.length > 0; }) || [], selectedSuffixGroups.find(function (it) { return it.length > 0; }) || [], itemGroupWeights, selectedWeightPerGroup);
    }
    var isCorrupted = selectedWeightPerImplicitGroup.size > 0;
    var selectedImplicits = selectedImplicitGroups[0] || [];
    var corruptions = getGroupWeights(possibleItemMods.getIn(['BASE', 'corrupted']));
    var corruptChance = CraftingCosts.oddsCorrupt(selectedImplicits, corruptions, selectedWeightPerImplicitGroup, 1);
    var doubleCorruptChance = CraftingCosts.oddsCorrupt(selectedImplicits, corruptions, selectedWeightPerImplicitGroup, 2);
    var corruptionCost = !isCorrupted ? false : (React.createElement("div", { className: styles.row },
        React.createElement("div", { className: 'f' },
            React.createElement(CurrencyStack, { key: 'aug-cost', currencyId: 30 }),
            Math.ceil(1 / (corruptChance * 0.25))),
        React.createElement("div", { className: 'f' },
            React.createElement(CurrencyStack, { stackSize: 1, key: 'aug-cost', currencyId: 32 }),
            Math.ceil(1 / (doubleCorruptChance * 0.25))),
        React.createElement("div", { className: 'f' },
            React.createElement(CurrencyStack, { stackSize: 2, key: 'aug-cost', currencyId: 32 }),
            selectedImplicits.length < 2 ? Number.POSITIVE_INFINITY : Math.ceil(1 / (doubleCorruptChance * doubleCorruptChance * 0.25)))));
    return (React.createElement("div", { className: styles.container },
        corruptionCost,
        React.createElement("div", { className: styles.row },
            React.createElement("div", { className: 'f' },
                getCurrency(9, altRegalCost.alterations),
                getCurrency(13, altRegalCost.augmentations)),
            React.createElement("div", { className: 'f' }, getCurrency(2, chaosSpamCost)))));
};
var mapStateToProps = function (state) { return ({
    possibleItemMods: select(state).getPossibleItemMods(),
    selectedWeightPerGroup: select(state).getWeightsOfSelectedModGroups(),
    selectedWeightPerImplicitGroup: select(state).getWeightsOfSelectedImplicitGroups(),
    selectedImplicitGroups: select(state).getSelectedImplicitGroups().filter(function (i) { return i.length > 0; }),
    selectedPrefixGroups: select(state).getSelectedPrefixGroups().filter(function (i) { return i.length > 0; }),
    selectedSuffixGroups: select(state).getSelectedSuffixGroups().filter(function (i) { return i.length > 0; }),
    addedDelveModIds: select(state).getAddedFossilModIds(),
}); };
export default connect(mapStateToProps)(CraftingCost);
