var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ModDomain } from '../types';
import { ItemInfluence } from './store/selections/types';
export var Reductions = {
    sum: function (total, value) { return total + value; },
    product: function (total, value) { return total * value; },
    flatten: function (combined, arr) { return combined.concat(arr); }
};
export function isDisabled(mod, selectedMods, item) {
    if (selectedMods.find(function (it) { return it.id === mod.id; })) {
        return false;
    }
    if (mod.domain === ModDomain.crafted) {
        return false;
    }
    var blockedByGroup = selectedMods.find(function (it) { return it.group === mod.group && it.modTypeKey !== mod.modTypeKey; });
    var weightModifiedToZero = /*mod.baseWeight && mod.baseWeight.weight > 0 &&*/ mod.modifiedWeight && mod.modifiedWeight.weight === 0;
    return blockedByGroup || weightModifiedToZero;
}
export function getItemTags(item, itemInfluences) {
    // TODO: update data to use the same "item influence" to get rid of this
    var influenceTags = itemInfluences.reduce(function (tags, influence) {
        if (influence === ItemInfluence.ELDER)
            return __spreadArrays(tags, [item.tagElder]);
        if (influence === ItemInfluence.SHAPER)
            return __spreadArrays(tags, [item.tagShaper]);
        if (influence === ItemInfluence.CRUSADER)
            return __spreadArrays(tags, [item.tagCrusader]);
        if (influence === ItemInfluence.EYRIE)
            return __spreadArrays(tags, [item.tagEyrie]);
        if (influence === ItemInfluence.BASILISK)
            return __spreadArrays(tags, [item.tagBasilisk]);
        if (influence === ItemInfluence.ADJUDICATOR)
            return __spreadArrays(tags, [item.tagAdjudicator]);
        return tags;
    }, []);
    return item.tags.concat(influenceTags);
}
export var percentToFloat = function (value) { return value * 0.01; };
export var weightMultiplier = function (weight) { return percentToFloat(weight.weight); };
