import * as React from "react";
var Tooltip = function (_a) {
    var text = _a.text, _b = _a.disable, disable = _b === void 0 ? false : _b, children = _a.children;
    if (disable) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement("div", { className: "btn btn-primary tooltip" },
        children,
        React.createElement("div", { className: "bottom" }, Array.isArray(text) ? text.map(function (it, index) { return React.createElement("div", { key: "" + it + index }, it); }) : text)));
};
export default Tooltip;
