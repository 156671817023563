import { ADD_SELECTED_MOD, REMOVE_SELECTED_MOD, SET_POSSIBLE_ITEM_MODS as SET_POSSIBLE_ITEM_MODS, SET_SELECTED_ITEM, ADD_SELECTED_FOSSIL, REMOVE_SELECTED_FOSSIL, TOGGLE_ITEM_INFLUENCE } from "./types";
export function addMod(mod, groupIndex) {
    return {
        type: ADD_SELECTED_MOD,
        mod: mod,
        groupIndex: groupIndex === undefined ? -1 : groupIndex
    };
}
export function selectFossil(mod) {
    return {
        type: ADD_SELECTED_FOSSIL,
        payload: mod
    };
}
export function unselectFossil(mod) {
    return {
        type: REMOVE_SELECTED_FOSSIL,
        payload: mod
    };
}
export function removeMod(modId) {
    return {
        type: REMOVE_SELECTED_MOD,
        modId: modId
    };
}
export function setSelectedItem(item) {
    return {
        type: SET_SELECTED_ITEM,
        payload: item
    };
}
/*
export function setItemModTypeKeys(modTypeKeys: ItemModTypeKeys) {
  return {
    type: SET_ITEM_MOD_TYPE_KEYS,
    itemModTypeKeys: modTypeKeys,
  };
}
*/
export function setPossibleItemMods(modsByTypeKey) {
    return {
        type: SET_POSSIBLE_ITEM_MODS,
        modsByTypeKey: modsByTypeKey
    };
}
export function toggleItemInfluence(influence) {
    return {
        type: TOGGLE_ITEM_INFLUENCE,
        influence: influence
    };
}
