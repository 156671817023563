import * as React from "react";
import ModRow from '../component/ModRow';
import CollapsibleContent from "../component/CollapsibleContent";
import ItemSelector from "../component/ItemSelector";
import { connect } from "react-redux";
import { selectWith as selectedWith } from "../store/selections/selectors";
import ToolBar from "../component/ToolBar/ToolBar";
import CraftingSection from "../component/CraftingSection/CraftingSection";
import BaseItems from "../component/BaseItems/BaseItems";
var Main = function (_a) {
    var tiersByTypeKey = _a.tiersByTypeKey, hasSelectedItem = _a.hasSelectedItem;
    var renderGroup = function (title, mods, folded) {
        if (folded === void 0) { folded = false; }
        if (mods.filter(function (x) { return !x.isEmpty(); }).isEmpty()) {
            return React.createElement(React.Fragment, null);
        }
        var group = function (modTypeKeys) { return (!modTypeKeys || modTypeKeys.isEmpty()) ? React.createElement("div", { className: "placeholder" }) : (React.createElement(React.Fragment, null, modTypeKeys.toIndexedSeq().toArray().map(function (modTypeKey) {
            return React.createElement(ModRow, { group: title, key: "mod-row-" + modTypeKey[0].modTypeKey, mods: modTypeKey });
        }))); };
        return (React.createElement(CollapsibleContent, { title: title, startCollapsed: folded, showDetails: true },
            React.createElement("div", { className: "row row_padded" },
                React.createElement("div", { className: "six columns" },
                    React.createElement("div", { className: "affix_type_header sticky bellow" }, "prefix"),
                    group(mods.get('prefix'))),
                React.createElement("div", { className: "six columns" },
                    React.createElement("div", { className: "affix_type_header sticky bellow" }, "suffix"),
                    group(mods.get('suffix'))))));
    };
    var splitGroup = function (title, mods, folded) {
        if (folded === void 0) { folded = false; }
        if (!mods || mods.isEmpty()) {
            return React.createElement(React.Fragment, null);
        }
        var modsArray = mods.toIndexedSeq().toArray();
        var halfIndex = Math.floor(modsArray.length / 2);
        var firstHalf = modsArray.slice(0, halfIndex);
        var secondHalf = modsArray.slice(halfIndex, modsArray.length);
        return (React.createElement(CollapsibleContent, { title: title, startCollapsed: folded, showDetails: true },
            React.createElement("div", { className: "row row_padded" },
                React.createElement("div", { className: "six columns" }, firstHalf.map(function (modTypeKey) {
                    return React.createElement(ModRow, { group: title, key: "mod-row-" + modTypeKey[0].modTypeKey, mods: modTypeKey });
                })),
                React.createElement("div", { className: "six columns" }, secondHalf.map(function (modTypeKey) {
                    return React.createElement(ModRow, { group: title, key: "mod-row-" + modTypeKey[0].modTypeKey, mods: modTypeKey });
                })))));
    };
    if (!hasSelectedItem) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ItemSelector, null),
            React.createElement(BaseItems, null)));
    }
    var corruptions = tiersByTypeKey.getIn(['BASE', 'corrupted']);
    return (React.createElement(React.Fragment, null,
        React.createElement(ItemSelector, null),
        React.createElement(ToolBar, null),
        tiersByTypeKey.entrySeq().map(function (_a) {
            var modGroup = _a[0], modsByAffixType = _a[1];
            return renderGroup(modGroup, modsByAffixType);
        }),
        splitGroup('corrupted', corruptions),
        React.createElement(CraftingSection, null)));
};
var mapStateToProps = function (state) { return ({
    tiersByTypeKey: selectedWith(state).getPossibleItemMods(),
    hasSelectedItem: !!selectedWith(state).getSelectedItem(),
}); };
export default connect(mapStateToProps)(Main);
