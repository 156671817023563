import { DamageType } from "../types";
import flatten from "lodash/flatten";
var Range = /** @class */ (function () {
    function Range() {
        var _this = this;
        this.min = 0;
        this.max = 0;
        this.roll = function (roll) {
            return _this.min + ((_this.max - _this.min) * roll);
        };
    }
    return Range;
}());
var combineStat = function (stats, key) { return (stats
    .filter(function (stat) { return stat.name == key; })
    .reduce(function (dmg, stat) {
    dmg.min = dmg.min + stat.min;
    dmg.max = dmg.max + stat.max;
    return dmg;
}, new Range())); };
export var toIncreaseMultiplier = function (value) { return 1 + value / 100; };
var applyIncrease = function (baseValue, increase) {
    return baseValue * toIncreaseMultiplier(increase);
};
export function getDps(base, dmgType, applyMods, roll) {
    if (dmgType === void 0) { dmgType = DamageType.elemental; }
    if (applyMods === void 0) { applyMods = []; }
    if (roll === void 0) { roll = 1; }
    if (!base)
        return undefined;
    var stats = flatten(applyMods.map(function (mod) { return mod.stats; }));
    var localAttackSpeedIncrease = combineStat(stats, "local_attack_speed_+%").roll(roll);
    var attacksPerSecond = applyIncrease(base.aps, localAttackSpeedIncrease);
    var toDps = function (minStat, maxStat) {
        var min = combineStat(stats, minStat).roll(roll);
        var max = combineStat(stats, maxStat).roll(roll);
        var avg = (min + max) / 2;
        return avg * attacksPerSecond;
    };
    var dps = 0;
    if (dmgType & DamageType.physical) {
        var quality = combineStat(stats, "local_item_quality_+").roll(roll) + 20;
        var increase = combineStat(stats, "local_physical_damage_+%").roll(roll) + quality;
        var min = combineStat(stats, "local_minimum_added_physical_damage").roll(roll) + base.min;
        var max = combineStat(stats, "local_maximum_added_physical_damage").roll(roll) + base.max;
        var avg = (min + max) / 2;
        dps += applyIncrease(avg, increase) * attacksPerSecond;
    }
    if (dmgType & DamageType.fire) {
        dps += toDps("local_minimum_added_fire_damage", "local_maximum_added_fire_damage");
    }
    if (dmgType & DamageType.cold) {
        dps += toDps("local_minimum_added_cold_damage", "local_maximum_added_cold_damage");
    }
    if (dmgType & DamageType.lightning) {
        dps += toDps("local_minimum_added_lightning_damage", "local_maximum_added_lightning_damage");
    }
    if (dmgType & DamageType.chaos) {
        dps += toDps("local_minimum_added_chaos_damage", "local_maximum_added_chaos_damage");
    }
    return dps;
}
