var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ADD_SELECTED_MOD, REMOVE_SELECTED_MOD, SET_HIGHLIGHT_GROUP, SET_ITEM_MODS, SET_FOSSIL_MAPPINGS, SET_ESSENCE_MAPPINGS, SET_ESSENCE_DATA, SET_EXTRA_TAGS, SET_CRAFTING_BENCH_MAPPINGS, SET_CURRENCIES, SET_ELDER_TAG, SET_SHAPER_TAG, SET_FILTER_TEXT, SET_LUCKINESS, SET_ITEMS, ADD_TOAST_MESSAGE, REMOVE_TOAST_MESSAGE, TOGGLE_TOOLBAR } from "./types";
import { Map } from "immutable";
var initialState = {
    selectedMods: [],
    highlightGroup: '',
    selectedGroups: [],
    extraTags: [],
    itemMods: {},
    fossilNameByModId: {},
    essenceMappingByModId: {},
    essenceByModId: {},
    craftingModTagsByModId: {},
    currencyByIndex: {},
    filter: '',
    luckiness: 0.85,
    itemByClass: Map(),
    fossilById: {},
    toastMessages: []
};
export default function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ADD_SELECTED_MOD: {
            var containsTag_1 = function (mod, tag) { return !!mod.spawnWeights.find(function (w) { return w.tag == tag && w.weight > 0; }); };
            var isShaperMod_1 = containsTag_1(action.payload, state.shaperTag);
            var isElderMod_1 = containsTag_1(action.payload, state.elderTag);
            var selectedMods = __spreadArrays(state.selectedMods.filter(function (m) { return (m.group !== action.payload.group && m.modTypeKey !== action.payload.modTypeKey) && !(isShaperMod_1 && containsTag_1(m, state.elderTag)) && !(isElderMod_1 && containsTag_1(m, state.shaperTag)); }), [action.payload]);
            //history.pushState({}, '', `/?item=${state.selectedItem}&mods=${selectedMods.map(it => it.id).join(',')}`);
            return __assign(__assign({}, state), { selectedMods: selectedMods, selectedGroups: selectedMods.map(function (x) { return x.group; }) });
        }
        case REMOVE_SELECTED_MOD: {
            var selectedMods = state.selectedMods.filter(function (x) { return x.id !== action.payload.id; });
            //history.pushState({}, '', `/?item=${state.selectedItem}&mods=${selectedMods.map(it => it.id).join(',')}`);
            return __assign(__assign({}, state), { selectedMods: selectedMods, selectedGroups: selectedMods.map(function (x) { return x.group; }) });
        }
        case SET_HIGHLIGHT_GROUP:
            return __assign(__assign({}, state), { highlightGroup: action.payload });
        case SET_ITEM_MODS:
            return __assign(__assign({}, state), { itemMods: __assign(__assign({}, state.itemMods), (_a = {}, _a[action.category] = action.mods, _a)) });
        case SET_FOSSIL_MAPPINGS:
            return __assign(__assign({}, state), { fossilNameByModId: action.payload });
        case SET_ESSENCE_MAPPINGS:
            return __assign(__assign({}, state), { essenceMappingByModId: action.payload });
        case SET_ESSENCE_DATA:
            return __assign(__assign({}, state), { essenceByModId: action.payload });
        case SET_EXTRA_TAGS:
            return __assign(__assign({}, state), { extraTags: action.payload });
        case SET_CRAFTING_BENCH_MAPPINGS:
            return __assign(__assign({}, state), { craftingModTagsByModId: action.payload });
        case SET_CURRENCIES:
            return __assign(__assign({}, state), { currencyByIndex: action.payload });
        case SET_ELDER_TAG:
            return __assign(__assign({}, state), { elderTag: action.payload });
        case SET_SHAPER_TAG:
            return __assign(__assign({}, state), { shaperTag: action.payload });
        case SET_FILTER_TEXT:
            return __assign(__assign({}, state), { filter: action.payload });
        case SET_LUCKINESS:
            return __assign(__assign({}, state), { luckiness: action.payload });
        case SET_ITEMS:
            return __assign(__assign({}, state), { itemByClass: action.payload });
        case ADD_TOAST_MESSAGE:
            return __assign(__assign({}, state), { toastMessages: __spreadArrays(state.toastMessages, [action.payload]) });
        case REMOVE_TOAST_MESSAGE:
            return __assign(__assign({}, state), { toastMessages: state.toastMessages.filter(function (it) { return it.id !== action.payload; }) });
        case TOGGLE_TOOLBAR:
            return __assign(__assign({}, state), { showToolbar: !state.showToolbar });
        default:
            return state;
    }
}
