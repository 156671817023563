import * as React from "react";
import { connect } from "react-redux";
import { selectWith } from "../../store/query/selectors";
var styles = require("./component.css");
var Toast = function (_a) {
    var messages = _a.messages;
    return (React.createElement("div", { className: styles.toast_container }, messages && messages.map(function (message) {
        return React.createElement("div", { key: message.id, className: styles.message }, message.msg);
    })));
};
var mapStateToProps = function (state) { return ({
    messages: selectWith(state.old).getToastMessages(),
}); };
export default connect(mapStateToProps)(Toast);
