import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import applyBaseMods from './middleware/applyBaseMods';
import applyMetaMods from './middleware/applyMetaMods';
import recalculateWeights from './middleware/recalculateWeights';
import reducer from "./query/reducers";
import selectionsReducer from "./selections/reducers";
import dataReducer from "./data/reducers";
var rootReducer = combineReducers({
    old: reducer,
    selections: selectionsReducer,
    data: dataReducer
});
export default function configureStore() {
    var store = createStore(rootReducer, composeWithDevTools(applyMiddleware(applyBaseMods, applyMetaMods, recalculateWeights)));
    window.dumpModsToConsole = function (ilvl) {
        var state = store.getState();
        var possibleMods = state.selections.possibleItemMods;
        var convert = function (mod) {
            return {
                modId: mod.id,
                ilvl: mod.ilvl,
                text: mod.translation[0],
                text2: mod.translation[1],
                group: mod.group,
                weight: mod.modifiedWeight.weight
            };
        };
        var dump = {
            item: { base: state.selections.selectedItem.name, ilvl: ilvl },
            prefixes: possibleMods.get('BASE').get('prefix').valueSeq().flatMap(function (x) { return x; }).toArray().filter(function (mod) { return mod.ilvl <= ilvl; }).map(function (mod) { return convert(mod); }),
            suffixes: possibleMods.get('BASE').get('suffix').valueSeq().flatMap(function (x) { return x; }).toArray().filter(function (mod) { return mod.ilvl <= ilvl; }).map(function (mod) { return convert(mod); }),
        };
        console.log(JSON.stringify(dump, null, 2));
    };
    return store;
}
