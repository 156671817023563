import { Reductions } from "./common";
// Weighted Random Sampling without Replacement
export function weightedRandomSampling(wantedIds, pool, samples) {
    if (wantedIds === void 0) { wantedIds = []; }
    var iteration = samples - 1;
    var wanted = pool.filter(function (item) { return wantedIds.includes(item.id); });
    if (wanted.length == 0 || samples <= 0) {
        return 0;
    }
    var unwanted = pool.filter(function (it) { return !wantedIds.includes(it.id); });
    var totalWeight = pool.reduce(function (total, option) { return total + option.weight; }, 0);
    var wantedWeight = wanted.map(function (x) { return x.weight; }).reduce(Reductions.sum, 0);
    var wantedProbability = wantedWeight / totalWeight;
    if (iteration == 0) {
        return wantedProbability;
    }
    var combined = unwanted.reduce(function (accumulator, item) {
        var unwantedProbability = item.weight / totalWeight;
        var wanted = unwantedProbability * weightedRandomSampling(wantedIds, pool.filter(function (it) { return it.id != item.id; }), iteration);
        return accumulator + wanted;
    }, 0.0);
    return wantedProbability + combined;
}
var affixCountData = [
    { prefixCount: 1, suffixCount: 0, rowCount: 1 },
    { prefixCount: 0, suffixCount: 1, rowCount: 1 },
    { prefixCount: 0, suffixCount: 3, rowCount: 1 },
    { prefixCount: 3, suffixCount: 0, rowCount: 1 },
    { prefixCount: 2, suffixCount: 0, rowCount: 1 },
    { prefixCount: 0, suffixCount: 2, rowCount: 1 },
    { prefixCount: 2, suffixCount: 1, rowCount: 2 },
    { prefixCount: 1, suffixCount: 2, rowCount: 2 },
    { prefixCount: 3, suffixCount: 1, rowCount: 3 },
    { prefixCount: 1, suffixCount: 3, rowCount: 3 },
    { prefixCount: 2, suffixCount: 2, rowCount: 6 },
    { prefixCount: 3, suffixCount: 2, rowCount: 6 },
    { prefixCount: 2, suffixCount: 3, rowCount: 6 }
];
// probability of getting a wanted amount of affixes
function affixOdds(total, wanted, affixOdds) {
    var notWantedNumber = total - wanted;
    var notWantedAffixOdds = 1 - affixOdds;
    if (wanted > total) {
        return 0;
    }
    if (total == 6) {
        return wanted === 3 ? 1 : 0;
    }
    if (total == 1) {
        return wanted == 1 ? affixOdds : notWantedAffixOdds;
    }
    if (total == 2) {
        return wanted == 1 ? 1 : 0; // garanteed
    }
    var getWantedCount = function (e) { return e.prefixCount; };
    var getOtherCount = function (e) { return e.suffixCount; };
    var largestCount = notWantedNumber > wanted ? notWantedNumber : wanted;
    var isLargestWanted = notWantedNumber > wanted
        ? function (e) { return getOtherCount(e) == largestCount; }
        : function (e) { return getWantedCount(e) == largestCount; };
    var limit = Math.min(total - 1, 3);
    if (wanted == notWantedNumber) {
        var filtered = affixCountData
            .filter(function (it) { return getOtherCount(it) + getWantedCount(it) <= total; })
            .filter(function (it) { return getOtherCount(it) >= limit || getWantedCount(it) >= limit || getOtherCount(it) + getWantedCount(it) == total; })
            .filter(function (it) { return !(getOtherCount(it) == notWantedNumber && getWantedCount(it) == wanted); });
        var probability_1 = filtered
            .reduce(function (total, item) { return total + item.rowCount * Math.pow(notWantedAffixOdds, getOtherCount(item)) * Math.pow(affixOdds, getWantedCount(item)); }, 0);
        return 1 - probability_1;
    }
    var probability = affixCountData
        .filter(function (it) { return getOtherCount(it) <= limit && getWantedCount(it) <= limit; })
        .filter(function (it) { return getOtherCount(it) + getWantedCount(it) <= total; })
        .filter(function (it) { return isLargestWanted(it); })
        .reduce(function (total, item) { return total + item.rowCount * Math.pow(notWantedAffixOdds, getOtherCount(item)) * Math.pow(affixOdds, getWantedCount(item)); }, 0);
    return probability;
}
export default Object.freeze({
    weightedRandomSampling: weightedRandomSampling,
    affixOdds: affixOdds
});
