import Probability from "./Probability";
import { Reductions } from "./common";
var logPercent = function (text, value) {
    //console.log(`${text}: ${(value * 100).toFixed(5)}%`)
};
var log = function (text) {
    //console.log(text)
};
// chaos spam
var chanceFourMod = 0.65; // magic number (verify in-game)
var chanceFiveMod = 0.25; // magic number (verify in-game)
var chanceSixMod = 0.1; // magic number (verify in-game)
// alteration spam
var chanceOneMod = 0.5; // magic number (verify in-game)
var chanceTwoMod = 0.5; // magic number (verify in-game)
var oddsOfModsInGroups = function (wantedGroups, itemGroupWeights, selectedWeightPerGroup) {
    if (wantedGroups === void 0) { wantedGroups = []; }
    var wantedPrefixGroupWeight = itemGroupWeights.prefix.filter(function (x) { return wantedGroups.includes(x.id); }).map(function (x) { return x.weight; }).reduce(Reductions.sum, 0);
    var wantedSuffixGroupWeight = itemGroupWeights.suffix.filter(function (x) { return wantedGroups.includes(x.id); }).map(function (x) { return x.weight; }).reduce(Reductions.sum, 0);
    var groupWeight = wantedPrefixGroupWeight || wantedSuffixGroupWeight;
    var selectedModsWeight = wantedGroups.map(function (groupId) { return selectedWeightPerGroup.get(groupId); }).reduce(Reductions.sum, 0);
    return (selectedModsWeight / groupWeight) || 0;
};
var probabilityByNumberOfMods = function (wantedGroups, itemGroupWeights, selectedWeightPerGroup, modCount) {
    var getSelectedChanceForAttempts = function (wantedGroups, pool, rolls) {
        var oddsOfGroup = Probability.weightedRandomSampling(wantedGroups, pool, rolls);
        return oddsOfGroup * oddsOfModsInGroups(wantedGroups, itemGroupWeights, selectedWeightPerGroup);
    };
    var combinedOdds = function (pool, rolls) {
        var groups = wantedGroups.filter(function (x) { return x.length; }).filter(function (x) { return pool.find(function (p) { return x.includes(p.id); }); });
        return groups.reduce(function (totalOdds, modGroup) {
            return totalOdds * getSelectedChanceForAttempts(modGroup, pool, rolls);
        }, 1);
    };
    var prefixWeight = itemGroupWeights.prefix.reduce(function (total, mod) { return total + mod.weight; }, 0);
    var suffixWeight = itemGroupWeights.suffix.reduce(function (total, mod) { return total + mod.weight; }, 0);
    var chanceForPrefix = prefixWeight / (prefixWeight + suffixWeight);
    var chanceForSuffix = 1 - chanceForPrefix;
    var p1 = combinedOdds(itemGroupWeights.prefix, 1);
    var p2 = combinedOdds(itemGroupWeights.prefix, 2);
    var p3 = combinedOdds(itemGroupWeights.prefix, 3);
    log("Odds of hitting prefix");
    logPercent("one prefix mods", p1);
    logPercent("two prefix mods", p2);
    logPercent("three prefix mods", p3);
    log('\n');
    var s1 = combinedOdds(itemGroupWeights.suffix, 1);
    var s2 = combinedOdds(itemGroupWeights.suffix, 2);
    var s3 = combinedOdds(itemGroupWeights.suffix, 3);
    log("Odds of hitting suffix");
    logPercent("one suffix mods", s1);
    logPercent("two suffix mods", s2);
    logPercent("three suffix mods", s3);
    log('\n');
    log('[prefix, suffix] %');
    logPercent("[1,1]", p1 * s1);
    logPercent("[2,1]", p2 * s1);
    logPercent("[3,1]", p3 * s1);
    logPercent("[1,2]", p1 * s2);
    logPercent("[2,2]", p2 * s2);
    logPercent("[3,2]", p3 * s2);
    logPercent("[1,3]", p1 * s3);
    logPercent("[2,3]", p2 * s3);
    logPercent("[3,3]", p3 * s3);
    log('\n');
    var oneSuffix = Probability.affixOdds(modCount, 1, chanceForSuffix);
    var twoSuffix = Probability.affixOdds(modCount, 2, chanceForSuffix);
    var threeSuffix = Probability.affixOdds(modCount, 3, chanceForSuffix);
    var onePrefix = Probability.affixOdds(modCount, 1, chanceForPrefix);
    var twoPrefix = Probability.affixOdds(modCount, 2, chanceForPrefix);
    var threePrefix = Probability.affixOdds(modCount, 3, chanceForPrefix);
    return onePrefix * p1 * oneSuffix * s1
        + twoPrefix * p2 * oneSuffix * s1
        + threePrefix * p3 * oneSuffix * s1
        + onePrefix * p1 * twoSuffix * s2
        + twoPrefix * p2 * twoSuffix * s2
        + threePrefix * p3 * twoSuffix * s2
        + onePrefix * p1 * threeSuffix * s3
        + twoPrefix * p2 * threeSuffix * s3
        + threePrefix * p3 * threeSuffix * s3;
};
var altAugCost = function (wantedPrefixes, wantedSuffixes, itemGroupWeights, selectedWeightPerGroup) {
    var prefixWeight = itemGroupWeights.prefix.reduce(function (total, mod) { return total + mod.weight; }, 0);
    var suffixWeight = itemGroupWeights.suffix.reduce(function (total, mod) { return total + mod.weight; }, 0);
    var chanceForPrefix = prefixWeight / (prefixWeight + suffixWeight);
    var chanceForSuffix = 1 - chanceForPrefix;
    var chanceInGroupPrefix = oddsOfModsInGroups(wantedPrefixes, itemGroupWeights, selectedWeightPerGroup);
    var chanceInGroupSuffix = oddsOfModsInGroups(wantedSuffixes, itemGroupWeights, selectedWeightPerGroup);
    var oddsOfWantedPrefix = Probability.weightedRandomSampling(wantedPrefixes, itemGroupWeights.prefix, 1) * chanceInGroupPrefix;
    var oddsOfWantedSuffix = Probability.weightedRandomSampling(wantedSuffixes, itemGroupWeights.suffix, 1) * chanceInGroupSuffix;
    // for single mod we only use alteration spam (0,5 chance to hit correct affix * chance to get mod)
    if (wantedPrefixes.length > 0 && wantedSuffixes.length === 0) {
        var oneMod = chanceOneMod * oddsOfWantedPrefix;
        var twoMods = chanceTwoMod * chanceInGroupPrefix * Probability.weightedRandomSampling(wantedPrefixes, itemGroupWeights.prefix.concat(itemGroupWeights.suffix), 1);
        return {
            alterations: 1 / (oneMod + twoMods),
            augmentations: 0
        };
    }
    // for single mod we only use alteration spam (0,5 chance to hit correct affix * chance to get mod)
    if (wantedPrefixes.length === 0 && wantedSuffixes.length > 0) {
        var oneMod = chanceOneMod * oddsOfWantedSuffix;
        var twoMods = chanceTwoMod * Probability.weightedRandomSampling(wantedSuffixes, itemGroupWeights.prefix.concat(itemGroupWeights.suffix), 1) * chanceInGroupSuffix;
        return {
            alterations: 1 / (oneMod + twoMods),
            augmentations: 0
        };
    }
    // double mods we calculate chance to hit alteration and aug
    if (wantedPrefixes.length > 0 && wantedSuffixes.length > 0) {
        // hitting both directly on alteration
        var oddsOfAlteration = chanceTwoMod * oddsOfWantedPrefix * oddsOfWantedSuffix;
        var oddsOfAugment = chanceOneMod * chanceForPrefix * oddsOfWantedPrefix * oddsOfWantedSuffix
            + chanceOneMod * chanceForSuffix * oddsOfWantedSuffix * oddsOfWantedPrefix;
        var totalAlterationOdds = oddsOfAlteration + oddsOfAugment;
        // aug cost should be (single affix, chance to hit prefix or suffix)
        var augmentCost = 1 / (chanceOneMod * oddsOfWantedPrefix +
            chanceOneMod * oddsOfWantedSuffix);
        return {
            alterations: 1 / totalAlterationOdds,
            augmentations: augmentCost
        };
    }
    return {
        alterations: Number.POSITIVE_INFINITY,
        augmentations: Number.POSITIVE_INFINITY
    };
};
var oddsChaosSpam = function (wantedGroups, itemGroupWeights, selectedWeightPerGroup) {
    if (wantedGroups.filter(function (group) { return group.length > 0; }).length === 0) {
        return Number.POSITIVE_INFINITY;
    }
    var chaosOdds = chanceFourMod * probabilityByNumberOfMods(wantedGroups, itemGroupWeights, selectedWeightPerGroup, 4)
        + chanceFiveMod * probabilityByNumberOfMods(wantedGroups, itemGroupWeights, selectedWeightPerGroup, 5)
        + chanceSixMod * probabilityByNumberOfMods(wantedGroups, itemGroupWeights, selectedWeightPerGroup, 6);
    return 1 / chaosOdds;
};
var oddsCorrupt = function (wantedGroups, itemGroupWeights, selectedWeightPerGroup, numberOfMods) {
    if (wantedGroups === void 0) { wantedGroups = []; }
    if (numberOfMods === void 0) { numberOfMods = 1; }
    var totalWantedGroupWeight = itemGroupWeights.filter(function (x) { return wantedGroups.includes(x.id); }).map(function (x) { return x.weight; }).reduce(Reductions.sum, 0);
    var selectedModsWeight = wantedGroups.map(function (wantedGroup) { return selectedWeightPerGroup.get(wantedGroup); }).reduce(Reductions.sum, 0);
    var chanceToHitGroup = Probability.weightedRandomSampling(wantedGroups, itemGroupWeights, numberOfMods);
    var chanceToHitInGroup = selectedModsWeight / totalWantedGroupWeight;
    return chanceToHitGroup * chanceToHitInGroup;
};
export default Object.freeze({
    oddsChaosSpam: oddsChaosSpam,
    altAugCost: altAugCost,
    oddsCorrupt: oddsCorrupt
});
