import * as React from "react";
var CollapsibleContent = function (_a) {
    var title = _a.title, _b = _a.startCollapsed, startCollapsed = _b === void 0 ? true : _b, children = _a.children;
    var _c = React.useState(startCollapsed), collapsed = _c[0], setCollapsed = _c[1];
    if (React.Children.count(children) === 0) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "collapsible-header bellow", onClick: function () { return setCollapsed(!collapsed); } }, title),
        collapsed ? false : children));
};
export default CollapsibleContent;
