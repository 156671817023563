import * as React from "react";
import * as ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import createStore from "./js/store/index";
import Main from "./js/page/Main";
import Axios from "axios";
import { setItemMods, setFossilMappings, setEssenceMappings, setEssenceData, setCraftingBenchMappings, setCurrencies, setItems } from "./js/store/query/actions";
import { setImplicitMods, setExplicitMods, setFossilDescriptions, setFossils } from "./js/store/data/actions";
import { ModDomain } from "./types";
import Settings from "./js/component/Settings";
import Toast from "./js/component/Toast/Toast";
import { List } from "immutable";
// let webpack process the css-libraries
require("./css/fonts.css");
require("./css/normalize.css");
require("./css/skeleton.css");
require("./css/custom.css"); // TODO: extract this file to component styles
require("./css/poe-sprite-small.css"); // TODO: remove the manual management of this one
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js').then(function (reg) {
        navigator.serviceWorker.addEventListener('message', function (event) {
            console.log("[ServiceWorker] " + event.data);
        });
        if (reg.installing) {
            console.log('Installing ServiceWorker');
        }
    }).catch(function (error) {
        console.error('Registration failed', error);
    });
}
var store = createStore();
var populateItemForDomain = function (domain) { return Axios.get(SERVICE_URL + "/" + domain.toLowerCase() + ".json").then(function (res) {
    var action = setItemMods(domain, res.data);
    store.dispatch(action);
    if (domain === ModDomain.implicits) {
        store.dispatch(setImplicitMods(res.data));
    }
    else {
        store.dispatch(setExplicitMods(res.data));
    }
}); };
populateItemForDomain(ModDomain.item);
populateItemForDomain(ModDomain.jewel);
populateItemForDomain(ModDomain.abyss_jewel);
populateItemForDomain(ModDomain.crafted);
populateItemForDomain(ModDomain.delve);
populateItemForDomain(ModDomain.implicits);
Axios.get(SERVICE_URL + "/fossilByModId.json").then(function (res) {
    var action = setFossilMappings(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/fossil_desc.json").then(function (res) {
    var action = setFossilDescriptions(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/essenceByModId.json").then(function (res) {
    var action = setEssenceMappings(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/essences.json").then(function (res) {
    var action = setEssenceData(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/craftingModTags.json").then(function (res) {
    var action = setCraftingBenchMappings(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/currencyById.json").then(function (res) {
    var action = setCurrencies(res.data);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/items.json").then(function (res) {
    var itemsByClass = List(res.data).groupBy(function (item) { return item.class; }).toOrderedMap();
    var action = setItems(itemsByClass);
    store.dispatch(action);
});
Axios.get(SERVICE_URL + "/fossils.json").then(function (res) {
    var action = setFossils(res.data);
    store.dispatch(action);
});
var theme = window.localStorage.getItem('theme');
theme && document.documentElement.setAttribute('data-theme', theme);
var App = function () {
    return (React.createElement(Provider, { store: store },
        React.createElement("div", { className: "main_wrapper" },
            React.createElement(Toast, null),
            React.createElement(Main, null)),
        React.createElement(Settings, null)));
};
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
