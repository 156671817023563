import * as React from "react";
import { selectWith } from "../../store/query/selectors";
import { connect } from "react-redux";
var styles = require("./component.css");
var CurrencyStack = function (_a) {
    var currencyId = _a.currencyId, stackSize = _a.stackSize, currencyById = _a.currencyById, onClick = _a.onClick;
    if (!currencyById || !currencyById[currencyId]) {
        return React.createElement(React.Fragment, null);
    }
    var url = currencyById[currencyId].url;
    return (React.createElement("div", { className: styles.cost, onClick: onClick },
        React.createElement("div", { className: url }),
        React.createElement("div", { className: styles.amount }, stackSize)));
};
var mapStateToProps = function (state) { return ({
    currencyById: selectWith(state.old).getCurrencyByIndex(),
}); };
export default connect(mapStateToProps)(CurrencyStack);
