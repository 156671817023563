export var selectors = {
    getSelectedModIds: function (state) { return state.selectedMods.map(function (m) { return m.id; }); }
};
export function selectWith(state) {
    return {
        getMaxItemLevel: function () { return state.selectedMods.reduce(function (acc, mod) { return Math.max(mod.ilvl, acc); }, 1); },
        getLuckiness: function () { return state.luckiness; },
        getItemsByClass: function () { return state.itemByClass; },
        getToastMessages: function () { return state.toastMessages || []; },
        getGroupHighlight: function () { return state.highlightGroup; },
        getCurrencyByIndex: function () { return state.currencyByIndex; },
        showToolbar: function () { return state.showToolbar; },
        getElderTag: function () { return state.elderTag; },
        getShaperTag: function () { return state.shaperTag; },
        isElderModSelected: function () { return state.selectedMods.filter(function (m) { return m.spawnWeights.find(function (w) { return w.tag == state.elderTag && w.weight > 0; }); }).length > 0; },
        isShaperModSelected: function () { return state.selectedMods.filter(function (m) { return m.spawnWeights.find(function (w) { return w.tag == state.shaperTag && w.weight > 0; }); }).length > 0; },
        getFilter: function () { return state.filter; },
        getItemMods: function () { return state.itemMods; },
    };
}
