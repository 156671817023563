var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from "lodash/isEqual"; // requires this syntax for webpack treeshaking to work
import PropTypes from 'prop-types';
import React from 'react';
import { KEYBOARD } from '../../keyboard';
import BaseItem from "../BaseItem/BaseItem";
import { Map } from "immutable";
var styles = require("./component.css");
;
;
var SearchBar = /** @class */ (function (_super) {
    __extends(SearchBar, _super);
    function SearchBar(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            value: '',
            cursor: -1
        };
        _this.handleInputChange = _this.handleInputChange.bind(_this);
        _this.onKeyDown = _this.onKeyDown.bind(_this);
        _this.clearSearch = _this.clearSearch.bind(_this);
        return _this;
    }
    SearchBar.prototype.componentDidUpdate = function (prevProps) {
        if (!isEqual(this.props.suggestions, prevProps.suggestions)) {
            this.setState({ cursor: -1 });
        }
    };
    SearchBar.prototype.handleInputChange = function (input) {
        var value = input.target.value;
        this.setState({ value: value });
        this.props.onSearchChanged(value);
    };
    SearchBar.prototype.clearSearch = function () {
        this.setState({ value: '', cursor: -1 });
        this.props.onSearchChanged('');
    };
    SearchBar.prototype.onSuggestionClick = function (option, event) {
        event && event.stopPropagation();
        this.props.onSelected(option);
        this.clearSearch();
    };
    SearchBar.prototype.getOptionProps = function (option, index) {
        var onSelected = this.props.onSelected;
        var cursor = this.state.cursor;
        return {
            key: option.name + index,
            item: option,
            className: cursor == index ? styles.highlighted : '',
            onClick: onSelected ? this.onSuggestionClick.bind(this, option) : undefined
        };
    };
    SearchBar.prototype.getSuggestion = function (term) {
        // presentation of suggestion item
        return term.name;
    };
    SearchBar.prototype.getSuggestions = function () {
        var _this = this;
        var _a = this.props, suggestions = _a.suggestions, showSuggestions = _a.showSuggestions, onSelectedClass = _a.onSelectedClass;
        if (!showSuggestions) {
            return false;
        }
        var index = 0;
        return (React.createElement(React.Fragment, null,
            React.createElement("ul", { className: styles.options, onMouseDown: function (e) { return e.preventDefault(); } }, suggestions.mapEntries(function (_a) {
                var className = _a[0], items = _a[1];
                return [className, (React.createElement("div", { key: "search-group-" + className },
                        React.createElement("span", { onClick: function () { return onSelectedClass(className); } }, className),
                        items.map(function (item) { return (React.createElement(BaseItem, __assign({}, _this.getOptionProps(item, index++)))); })))];
            }).valueSeq())));
    };
    SearchBar.prototype.onKeyDown = function (event) {
        var suggestions = this.props.suggestions;
        var cursor = this.state.cursor;
        var keyCode = event.keyCode;
        if (KEYBOARD.isEscapeKey(keyCode)) {
            this.setState({ cursor: -1 });
        }
        else if (KEYBOARD.isDownKey(keyCode) /* && cursor < suggestions.length - 1*/) {
            this.setState({ cursor: cursor + 1 });
        }
        else if (KEYBOARD.isUpKey(keyCode) && cursor > 0) {
            this.setState({ cursor: cursor - 1 });
        }
        else if (KEYBOARD.isEnterKey(keyCode) && cursor !== -1) {
            //this.onSuggestionClick(suggestions[cursor], null);
        }
    };
    SearchBar.prototype.render = function () {
        var _a = this.props, onBlur = _a.onBlur, onFocus = _a.onFocus, searchRef = _a.searchRef, children = _a.children;
        var value = this.state.value;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.search_bar },
                children,
                React.createElement("input", { "aria-label": "search for item modifiers", ref: searchRef, className: styles.seach_input, type: "text", placeholder: this.props.placeholder, value: value, 
                    //onMouseDown={this.onMouseDown}
                    onKeyDown: this.onKeyDown, onChange: this.handleInputChange, onFocus: onFocus, onBlur: onBlur, autoFocus: true })),
            this.getSuggestions()));
    };
    SearchBar.defaultProps = {
        suggestions: Map(),
        showSuggestions: false,
        onFocus: function () { },
        onBlur: function () { },
        placeholder: "Search for any mod here...",
        searchRef: React.createRef()
    };
    SearchBar.propTypes = {
        onSearchChanged: PropTypes.func.isRequired,
        onSelected: PropTypes.func.isRequired
    };
    return SearchBar;
}(React.PureComponent));
export default SearchBar;
