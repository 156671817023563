const KEY_CODES = Object.freeze({
    ENTER: 13,
    ESCAPE: 27,
    TAB: 9,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40
});

const KEY_NAMES = Object.freeze({
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_UP: 'ArrowUp',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_DOWN: 'ArrowDown'
});

const KEYBOARD = Object.freeze({
    isEnterKey: key => key === KEY_NAMES.ENTER || key === KEY_CODES.ENTER,
    isEscapeKey: key => key === KEY_NAMES.ESCAPE || key === KEY_CODES.ESCAPE,
    isLeftKey: key => key === KEY_NAMES.ARROW_LEFT || key === KEY_CODES.ARROW_LEFT,
    isUpKey: key => key === KEY_NAMES.ARROW_UP || key === KEY_CODES.ARROW_UP,
    isRightKey: key => key === KEY_NAMES.ARROW_RIGHT || key === KEY_CODES.ARROW_RIGHT,
    isDownKey: key => key === KEY_NAMES.ARROW_DOWN || key === KEY_CODES.ARROW_DOWN,
    isTabKey: key => key === KEY_CODES.TAB
});

export {
    KEY_CODES,
    KEY_NAMES,
    KEYBOARD
};