var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ADD_SELECTED_MOD, REMOVE_SELECTED_MOD, SET_POSSIBLE_ITEM_MODS, SET_SELECTED_ITEM, ADD_SELECTED_FOSSIL, REMOVE_SELECTED_FOSSIL, TOGGLE_ITEM_INFLUENCE } from "./types";
import { Map } from "immutable";
var initialState = {
    selectedPrefix: [[], [], []],
    selectedSuffix: [[], [], []],
    selectedImplicits: [[]],
    fossils: [],
    itemInfluence: [],
    //itemModTypeKeys: Map<ModGroupName, Map<AffixType, ModTypeKey[]>>(),
    possibleItemMods: Map(),
    possibleItemModsFlat: Map(),
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ADD_SELECTED_MOD: {
            //const containsTag = (mod: ModData, tag: string) => !!mod.weights.find(w => w.tag == tag && w.weight > 0)
            //const isShaperMod = containsTag(action.mod, state.shaperTag)
            //const isElderMod = containsTag(action.mod, state.elderTag)
            //const selectedMods = [...state.selectedMods.filter(m => (m.group !== action.payload.group && m.modTypeKey !== action.payload.modTypeKey) && !(isShaperMod && containsTag(m, state.elderTag)) && !(isElderMod && containsTag(m, state.shaperTag)) ), action.payload]
            //const group = state.groups[action.group]
            //  .filter(m => (m.group !== action.mod.group && m.modTypeKey !== action.mod.modTypeKey) )
            //const selectedMods = [...group, action.mod]
            var updateGroup = function (affixGroups) {
                // ensure the added mod isn't a duplicate
                var filteredGroups = affixGroups.map(function (group) { return group.filter(function (modId) { return modId !== action.mod.id; }); });
                var emptyGroupIndex = filteredGroups.findIndex(function (group) { return group.length === 0; });
                var emptyOrLastIndex = (emptyGroupIndex !== -1 ? emptyGroupIndex : filteredGroups.length - 1);
                var addToIndex = action.groupIndex !== -1 ? action.groupIndex : emptyOrLastIndex;
                filteredGroups[addToIndex] = __spreadArrays(filteredGroups[addToIndex], [action.mod.id]);
                return filteredGroups;
            };
            if (action.mod.type === 'prefix') {
                return __assign(__assign({}, state), { selectedPrefix: updateGroup(state.selectedPrefix) });
            }
            if (action.mod.type === 'suffix') {
                return __assign(__assign({}, state), { selectedSuffix: updateGroup(state.selectedSuffix) });
            }
            return __assign(__assign({}, state), { selectedImplicits: updateGroup(state.selectedImplicits.map(function (mods) { return mods.filter(function (modId) { return !state.selectedItem.implicitMods.includes(modId); }); })) });
        }
        case REMOVE_SELECTED_MOD: {
            var implicits = state.selectedImplicits.map(function (group) { return group.filter(function (modId) { return modId !== action.modId; }); });
            var hasImplicit = !!implicits.find(function (mod) { return mod.length > 0; });
            return __assign(__assign({}, state), { selectedPrefix: state.selectedPrefix.map(function (group) { return group.filter(function (modId) { return modId !== action.modId; }); }), selectedSuffix: state.selectedSuffix.map(function (group) { return group.filter(function (modId) { return modId !== action.modId; }); }), selectedImplicits: hasImplicit ? implicits : [state.selectedItem.implicitMods] });
        }
        case SET_SELECTED_ITEM: {
            return __assign(__assign({}, initialState), { selectedItem: action.payload, itemInfluence: [], 
                // TODO: keep selected mods, instead show popup if selected mods are unavailable offering to clear and/or remove mods
                selectedImplicits: [action.payload.implicitMods], selectedPrefix: [[], [], []], selectedSuffix: [[], [], []] });
        }
        case ADD_SELECTED_FOSSIL: {
            var fossils = state.fossils.filter(function (fossil) { return fossil.id !== action.payload.id; });
            return __assign(__assign({}, state), { fossils: __spreadArrays(fossils, [action.payload]) });
        }
        case REMOVE_SELECTED_FOSSIL: {
            return __assign(__assign({}, state), { fossils: state.fossils.filter(function (fossil) { return fossil.id !== action.payload.id; }) });
        }
        case TOGGLE_ITEM_INFLUENCE: {
            if (state.itemInfluence.includes(action.influence)) { // remove
                return __assign(__assign({}, state), { itemInfluence: state.itemInfluence.filter(function (influence) { return influence !== action.influence; }) });
            }
            if (state.itemInfluence.length < 2) { // add
                return __assign(__assign({}, state), { itemInfluence: __spreadArrays(state.itemInfluence, [action.influence]) });
            }
            return state; // max two influences
        }
        case SET_POSSIBLE_ITEM_MODS: {
            return __assign(__assign({}, state), { possibleItemMods: action.modsByTypeKey });
        }
        default:
            return state;
    }
}
