import * as React from "react";
import DivineLuckConfig from "./DivineLuckConfig";
import { selectWith } from "../store/query/selectors";
import { connect } from "react-redux";
var Settings = function (_a) {
    var showToolbar = _a.showToolbar;
    var _b = React.useState(window.localStorage.getItem('theme') == 'dark'), isDarkMode = _b[0], setDarkMode = _b[1];
    var wrappingClass = showToolbar ? "toolbar-position active" : "toolbar-position";
    var setTheme = function (mode) {
        window.localStorage.setItem('theme', mode);
        document.documentElement.setAttribute('data-theme', mode);
        setDarkMode(mode == 'dark');
    };
    return (React.createElement("div", { className: wrappingClass },
        React.createElement("div", null,
            React.createElement("div", { className: "main_wrapper toolbar-container" },
                React.createElement("div", { className: "toolbar-setting" },
                    React.createElement("span", null, "Theme"),
                    React.createElement("div", { className: "spaced_row" },
                        React.createElement("div", { className: isDarkMode ? 'highlight' : '', onClick: function () { return setTheme('dark'); } }, "dark"),
                        React.createElement("div", { className: !isDarkMode ? 'highlight' : '', onClick: function () { return setTheme('light'); } }, "light"))),
                React.createElement(DivineLuckConfig, null)))));
};
var mapStateToProps = function (state) { return ({
    showToolbar: selectWith(state.old).showToolbar(),
}); };
export default connect(mapStateToProps)(Settings);
