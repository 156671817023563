import * as React from "react";
import { selectWith } from "../store/query/selectors";
import { connect } from "react-redux";
import { setLuckiness } from '../store/query/actions';
var DivineLuckConfig = function (_a) {
    var luckiness = _a.luckiness, setLuckiness = _a.setLuckiness;
    return (React.createElement("div", { className: "toolbar-setting" },
        React.createElement("span", null, "How lucky Divine rolls?"),
        React.createElement("div", { className: "spaced_row" },
            React.createElement("div", { className: luckiness == 0 ? 'highlight' : '', onClick: function () { return setLuckiness(0); } }, "MIN"),
            React.createElement("div", { className: luckiness == 0.5 ? 'highlight' : '', onClick: function () { return setLuckiness(0.5); } }, "50%"),
            React.createElement("div", { className: luckiness == 0.85 ? 'highlight' : '', onClick: function () { return setLuckiness(0.85); } }, "85%"),
            React.createElement("div", { className: luckiness == 1 ? 'highlight' : '', onClick: function () { return setLuckiness(1); } }, "MAX"))));
};
var mapStateToProps = function (state) { return ({
    luckiness: selectWith(state.old).getLuckiness()
}); };
export default connect(mapStateToProps, { setLuckiness: setLuckiness })(DivineLuckConfig);
