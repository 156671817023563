export var ADD_SELECTED_MOD = "ADD_SELECTED_MOD_NEW"; // TODO: update this when old one is removed
export var REMOVE_SELECTED_MOD = "REMOVE_SELECTED_MOD_NEW"; // TODO: update this when old one is removed
export var SET_POSSIBLE_ITEM_MODS = "SET_POSSIBLE_ITEM_MODS";
export var SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export var TOGGLE_ITEM_INFLUENCE = "TOGGLE_ITEM_INFLUENCE";
export var ADD_SELECTED_FOSSIL = "ADD_SELECTED_FOSSIL";
export var REMOVE_SELECTED_FOSSIL = "REMOVE_SELECTED_FOSSIL";
export var ItemInfluence;
(function (ItemInfluence) {
    ItemInfluence[ItemInfluence["SHAPER"] = 0] = "SHAPER";
    ItemInfluence[ItemInfluence["ELDER"] = 1] = "ELDER";
    ItemInfluence[ItemInfluence["CRUSADER"] = 2] = "CRUSADER";
    ItemInfluence[ItemInfluence["EYRIE"] = 3] = "EYRIE";
    ItemInfluence[ItemInfluence["BASILISK"] = 4] = "BASILISK";
    ItemInfluence[ItemInfluence["ADJUDICATOR"] = 5] = "ADJUDICATOR";
})(ItemInfluence || (ItemInfluence = {}));
