import { ADD_SELECTED_MOD, REMOVE_SELECTED_MOD, SET_HIGHLIGHT_GROUP, SET_ITEM_MODS, SET_FOSSIL_MAPPINGS, SET_ESSENCE_MAPPINGS, SET_ESSENCE_DATA, SET_EXTRA_TAGS, SET_CRAFTING_BENCH_MAPPINGS, SET_CURRENCIES, SET_ELDER_TAG, SET_SHAPER_TAG, SET_FILTER_TEXT, SET_LUCKINESS, SET_ITEMS, ADD_TOAST_MESSAGE, REMOVE_TOAST_MESSAGE, TOGGLE_TOOLBAR, } from "./types";
export function addSelectedMod(mod) {
    return {
        type: ADD_SELECTED_MOD,
        payload: mod
    };
}
export function removeSelectedMod(mod) {
    return {
        type: REMOVE_SELECTED_MOD,
        payload: mod
    };
}
export function setHighlightGroup(correctGroup) {
    return {
        type: SET_HIGHLIGHT_GROUP,
        payload: correctGroup || ''
    };
}
export function setItemMods(category, mods) {
    return {
        type: SET_ITEM_MODS,
        category: category,
        mods: mods
    };
}
export function setFossilMappings(mappings) {
    return {
        type: SET_FOSSIL_MAPPINGS,
        payload: mappings
    };
}
export function setEssenceMappings(mappings) {
    return {
        type: SET_ESSENCE_MAPPINGS,
        payload: mappings
    };
}
export function setEssenceData(essences) {
    return {
        type: SET_ESSENCE_DATA,
        payload: essences
    };
}
export function setExtraTags(tags) {
    return {
        type: SET_EXTRA_TAGS,
        payload: tags
    };
}
export function setCraftingBenchMappings(mappings) {
    return {
        type: SET_CRAFTING_BENCH_MAPPINGS,
        payload: mappings
    };
}
export function setCurrencies(currencies) {
    return {
        type: SET_CURRENCIES,
        payload: currencies
    };
}
export function setElderTag(tag) {
    return {
        type: SET_ELDER_TAG,
        payload: tag
    };
}
export function setShaperTag(tag) {
    return {
        type: SET_SHAPER_TAG,
        payload: tag
    };
}
export function setFilterText(text) {
    return {
        type: SET_FILTER_TEXT,
        payload: text
    };
}
export function setLuckiness(luckiness) {
    return {
        type: SET_LUCKINESS,
        payload: luckiness
    };
}
export function setItems(items) {
    return {
        type: SET_ITEMS,
        payload: items
    };
}
export function addToastMessage(toast) {
    return {
        type: ADD_TOAST_MESSAGE,
        payload: toast
    };
}
export function removeToastMessage(toastId) {
    return {
        type: REMOVE_TOAST_MESSAGE,
        payload: toastId
    };
}
export function toggleToolbar() {
    return {
        type: TOGGLE_TOOLBAR,
    };
}
