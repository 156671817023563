import { ModDomain } from "../../../types";
import { Map } from 'immutable';
export var selectors = {
    getItemImplicits: function (state) { return function (item) {
        if (item === void 0) { item = {}; }
        var implicits = state.data.implicits || Map();
        var modIds = item.implicitMods || [];
        return modIds.map(function (id) {
            return implicits.getIn([ModDomain.item, id]);
        });
    }; },
    getFossilDescription: function (state) { return function (descId) {
        return state.data.fossilDescriptions[descId];
    }; },
    getFossilsByModId: function (state) { return function (modId) {
        var fossilIds = state.old.fossilNameByModId[modId] || [];
        fossilIds.map(function (id) { return state.data.fossils[id]; });
        return fossilIds.map(function (id) { return state.data.fossils[id]; });
    }; },
    getFossils: function (state) {
        return Object.values(state.data.fossils);
    },
};
