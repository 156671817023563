import * as React from "react";
import GroupSelector from "../GroupSelector/GroupSelector";
import { connect } from "react-redux";
import { addMod } from "../../../store/selections/actions";
var styles = require("./component.css");
var SelectedMod = function (_a) {
    var groupId = _a.groupId, mod = _a.mod, addMod = _a.addMod, hasGroups = _a.hasGroups;
    var _b = React.useState(false), showSelector = _b[0], setShowSelector = _b[1];
    var color = '';
    switch (groupId) {
        case 0:
            color = styles.group1;
            break;
        case 1:
            color = styles.group2;
            break;
        case 2:
            color = styles.group3;
            break;
    }
    var itemClass = hasGroups ? styles.container + " " + color : styles.container;
    return (React.createElement("div", { onClick: function (e) { return setShowSelector(true); }, className: itemClass },
        mod.translation.map(function (text, index) { return React.createElement("span", { key: "selected-" + mod.id + "-translation-" + index }, text); }),
        showSelector && hasGroups ? React.createElement(GroupSelector, { dismiss: function () { return setShowSelector(false); }, onSelectGroup: function (index) { return addMod(mod, index); } }) : false));
};
export default connect(null, { addMod: addMod })(SelectedMod);
