import React from "react";
import { connect } from "react-redux";
import { selectors } from "../../store/data/selectors";
import { selectWith } from "../../store/selections/selectors";
import { selectFossil, unselectFossil } from "../../store/selections/actions";
import Fossil from "../Fossil";
var styles = require("./component.css");
var FossilCrafting = function (_a) {
    var fossils = _a.fossils, selectedFossils = _a.selectedFossils, getFossilDescription = _a.getFossilDescription, selectFossil = _a.selectFossil, unselectFossil = _a.unselectFossil;
    var halfIndex = Math.floor(fossils.length / 2);
    var firstHalf = fossils.slice(0, halfIndex);
    var secondHalf = fossils.slice(halfIndex, fossils.length);
    var blockedDescriptions = selectedFossils.reduce(function (arr, fossil) { return arr.concat(fossil.blockedDescriptionKeys); }, []);
    var selectedIds = selectedFossils.map(function (it) { return it.id; });
    var fossilClass = function (id) {
        var selected = selectedIds.includes(id) ? styles.selected : '';
        return styles.fossil + " " + selected;
    };
    var descClass = function (id) {
        return blockedDescriptions.includes(id) ? styles.disabled : '';
    };
    var handleClick = function (fossil) {
        if (selectedIds.includes(fossil.id)) {
            unselectFossil(fossil);
        }
        else {
            selectFossil(fossil);
        }
    };
    var render = function (data) { return (React.createElement(React.Fragment, null, data.map(function (fossil) { return (React.createElement("div", { className: fossilClass(fossil.id), onClick: function () { return handleClick(fossil); } },
        React.createElement("div", { className: styles.name },
            React.createElement(Fossil, { fossils: [fossil] }),
            fossil.name),
        React.createElement("div", { className: styles.description }, fossil.descriptionsKeys.map(function (key) {
            return React.createElement("span", { className: descClass(key) }, getFossilDescription(key));
        })))); }))); };
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "six columns" }, render(firstHalf)),
        React.createElement("div", { className: "six columns" }, render(secondHalf))));
};
var mapStateToProps = function (state) { return ({
    fossils: selectors.getFossils(state),
    getFossilDescription: selectors.getFossilDescription(state),
    selectedFossils: selectWith(state).getSelectedFossils()
}); };
export default connect(mapStateToProps, { selectFossil: selectFossil, unselectFossil: unselectFossil })(FossilCrafting);
