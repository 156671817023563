import { ModDomain } from '../../../types';
import { setExtraTags } from '../query/actions';
import { ADD_SELECTED_MOD, REMOVE_SELECTED_MOD } from '../selections/types';
var idsToMod = function (state, modIds, includeCrafted) {
    if (includeCrafted === void 0) { includeCrafted = false; }
    // TODO: flatten all mod types and index by id
    var itemMods = [ModDomain.abyss_jewel, ModDomain.delve, ModDomain.item, ModDomain.jewel, ModDomain.implicits, ModDomain.crafted, ModDomain.elder, ModDomain.shaper]
        .reduce(function (acc, domain) { return acc.concat(state.old.itemMods[domain]); }, []);
    return modIds
        .map(function (modId) { return itemMods.find(function (x) { return x.id === modId; }); })
        .filter(function (mod) { return mod !== undefined; });
};
export default (function (store) { return function (next) { return function (action) {
    var triggeringActions = [ADD_SELECTED_MOD, REMOVE_SELECTED_MOD];
    if (!triggeringActions.includes(action.type)) {
        return next(action);
    }
    //const oldState = store.getState()
    var result = next(action);
    var state = store.getState();
    var implicits = state.selections.selectedImplicits || [];
    var prefix = state.selections.selectedPrefix || [];
    var suffix = state.selections.selectedSuffix || [];
    var allSelectedMods = implicits.concat(prefix).concat(suffix);
    var extraTags = allSelectedMods
        .reduce(function (acc, group) { return acc.concat(idsToMod(state, group, false)); }, [])
        .reduce(function (tags, modTags) { return tags.concat(modTags.tags || []); }, []);
    setTimeout(function () { return store.dispatch(setExtraTags(extraTags)); }, 0);
    return result;
}; }; });
