import * as React from "react";
import { connect } from "react-redux";
import { selectWith, selectors } from "../store/query/selectors";
import { selectors as dataSelect } from "../store/data/selectors";
import { selectWith as select } from "../store/selections/selectors";
import { addSelectedMod, removeSelectedMod, setHighlightGroup } from "../store/query/actions";
import { addMod, removeMod } from "../store/selections/actions";
import memoize from 'lodash/memoize';
import CurrencyStack from "./CurrencyStack/CurrencyStack";
import Essence from "./Essence";
import Fossil from "./Fossil";
import { isDisabled } from "../common";
var notFiltered = function (translations, filter) {
    if (filter === void 0) { filter = ''; }
    var needles = filter.toLocaleLowerCase().split(' ').filter(function (word) { return word.trim().length; });
    return !!translations.find(function (translation) {
        var heystack = translation.toLocaleLowerCase();
        return needles.reduce(function (acc, needle) { return acc && heystack.indexOf(needle) != -1; }, true);
    });
};
var ModRow = function (props) {
    var mods = props.mods, group = props.group, cacheModKey = props.cacheModKey, selectedMod = props.selectedMod, selectedMods = props.selectedMods, addMod = props.addMod, removeMod = props.removeMod, addSelectedMod = props.addSelectedMod, removeSelectedMod = props.removeSelectedMod, highlightGroup = props.highlightGroup, getFossilsByModId = props.getFossilsByModId, getEssenceForModId = props.getEssenceForModId, item = props.item, filter = props.filter;
    var _a = React.useState(false), expanded = _a[0], setExpanded = _a[1];
    var bestMod = mods.reduce(function (acc, item) { return item.ilvl > acc.ilvl ? item : acc; }, { ilvl: 0 });
    var mod = selectedMod || bestMod;
    var modSpawnTags = mod.spawnWeights.map(function (it) { return it.tag; });
    var hideAltasModInBaseGroup = group === 'BASE' && (modSpawnTags.includes(item.tagElder) || modSpawnTags.includes(item.tagShaper)
        || modSpawnTags.includes(item.tagCrusader) || modSpawnTags.includes(item.tagEyrie)
        || modSpawnTags.includes(item.tagBasilisk) || modSpawnTags.includes(item.tagAdjudicator));
    if (!notFiltered(mod.translation, filter) || hideAltasModInBaseGroup) {
        return React.createElement(React.Fragment, null);
    }
    var isEssenceCraftable = !!getEssenceForModId(mod.id);
    var disabled = /*!isEssenceCraftable && */ isDisabled(mod, selectedMods, item);
    var getContainerClass = function (mod) { return (disabled ? 'f1 disabled' : 'f1 clickable') + (mod.id == (selectedMod && selectedMod.id) ? ' selected' : ''); };
    var hoveredClass = highlightGroup === mod.group ? ['hovered'] : [];
    var selectedRow = 'mod_row selected';
    var hoverableRow = ['mod_row'].concat(hoveredClass).join(' ');
    var getItemLevel = function (mod, expandable) {
        if (expandable === void 0) { expandable = false; }
        var onItemLevelClick = function (e) {
            e.preventDefault();
            //!expandable && setMaxItemLevel(mod.ilvl);
            expandable && mods.length > 1 && setExpanded(true);
        };
        if (mod.cost && Object.keys(mod.cost).length > 0) {
            return Object.keys(mod.cost).map(function (key) {
                var currencyId = parseInt(key);
                var cost = mod.cost[currencyId];
                var currencyReactKey = mod.id + "-" + currencyId;
                return React.createElement(CurrencyStack, { key: currencyReactKey, currencyId: currencyId, stackSize: cost, onClick: onItemLevelClick });
            });
        }
        var className = bestMod.ilvl === mod.ilvl ? 'ilvl ilvl_max_tier' : 'ilvl';
        return (React.createElement("span", { className: className, onClick: onItemLevelClick }, mod.ilvl));
    };
    var onClick = function (mod) {
        var clickedSelectedMod = selectedMod && selectedMod.id === mod.id;
        !clickedSelectedMod && addSelectedMod(mod);
        !clickedSelectedMod && addMod(mod);
        clickedSelectedMod && removeSelectedMod(mod);
        clickedSelectedMod && removeMod(mod.id);
        setExpanded(false);
    };
    var tiers = function () { return mods.map(function (tier) {
        var fossils = getFossilsByModId(tier.id);
        var essence = getEssenceForModId(tier.id);
        var disabled = isDisabled(mod, selectedMods, item);
        return (React.createElement("div", { key: "mod-row-" + tier.id, className: getContainerClass(tier) },
            getItemLevel(tier),
            React.createElement(Fossil, { fossils: fossils }),
            React.createElement(Essence, { essence: essence, showTooltip: !disabled }),
            React.createElement("div", { className: selectedMod && tier.id == selectedMod.id ? selectedRow : 'mod_row', onClick: function () { return onClick(tier); } }, tier.translation.map(function (text, index) { return React.createElement("span", { key: "mod-" + tier.id + "-translation-" + index }, text); }))));
    }); };
    var craftable = mods.reduce(function (acc, mod) {
        acc.essence = getEssenceForModId(mod.id) || acc.essence;
        acc.fossil = (acc.fossil.length > 0 && acc.fossil) || getFossilsByModId(mod.id);
        return acc;
    }, {
        essence: undefined,
        fossil: []
    });
    var single = function () { return (React.createElement("div", { key: "mod-" + cacheModKey, className: getContainerClass(mod) },
        getItemLevel(mod, true),
        React.createElement(Fossil, { fossils: craftable.fossil }),
        React.createElement(Essence, { essence: craftable.essence }),
        React.createElement("div", { className: selectedMod ? selectedRow : hoverableRow, onClick: function () { return onClick(mod); } }, mod.translation.map(function (text, index) { return React.createElement("span", { key: "mod-" + mod.id + "-translation-" + index }, text); })))); };
    return (React.createElement("div", { className: "mod-tier-container" /*onMouseLeave={() => setExpanded(false)}*/ }, !expanded ? single() :
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mod-tier-left-line", onClick: function () { return setExpanded(false); } }),
            React.createElement("div", { className: "mod_tiers_group" }, tiers()))));
};
var cacheIds = memoize(function (tiers) { return tiers.map(function (it) { return it.id; }); });
var cacheModKey = memoize(function (tiers) { return tiers.reduce(function (acc, it) { return acc + "," + it; }, ''); });
var cacheSelectedItem = memoize(function (tiers, selectedIds) { return tiers.find(function (it) { return selectedIds.includes(it.id); }); }, function (tiers, selectedIds) { return cacheIds(tiers) + " " + selectedIds; });
var mapStateToProps = function (state, props) { return ({
    cacheModKey: cacheModKey(cacheIds(props.mods)),
    selectedMod: cacheSelectedItem(props.mods, selectors.getSelectedModIds(state.old)),
    highlightGroup: selectWith(state.old).getGroupHighlight(),
    item: select(state).getSelectedItem(),
    //selectedMods: select(state).getAllSelectedMods(), // TODO: reimplement
    selectedMods: [],
    getFossilsByModId: dataSelect.getFossilsByModId(state),
    getEssenceForModId: select(state).getEssenceForModId,
    elderTag: selectWith(state.old).getElderTag(),
    shaperTag: selectWith(state.old).getShaperTag(),
    filter: selectWith(state.old).getFilter(),
}); };
export default connect(mapStateToProps, { addSelectedMod: addSelectedMod, removeSelectedMod: removeSelectedMod, setHighlightGroup: setHighlightGroup, addMod: addMod, removeMod: removeMod })(ModRow);
