import * as React from "react";
import Tooltip from "./Tooltip";
var getImage = function (spriteName, disabled) {
    if (disabled === void 0) { disabled = false; }
    var className = [spriteName].concat(disabled ? ['disabled'] : []).join(' ');
    return className ? React.createElement("div", { className: className }) : false;
};
var Fossil = function (_a) {
    var fossils = _a.fossils, _b = _a.showTooltip, showTooltip = _b === void 0 ? true : _b;
    if (!fossils || fossils.length == 0) {
        return React.createElement(React.Fragment, null);
    }
    var tooltipText = fossils.map(function (it) { return it.name; });
    return (React.createElement(Tooltip, { text: tooltipText, disable: !showTooltip }, getImage(fossils[0].icon)));
};
export default Fossil;
