import * as React from "react";
import { selectWith } from "../store/query/selectors";
import { connect } from "react-redux";
import Tooltip from "./Tooltip";
var getImage = function (spriteName, disabled) {
    if (disabled === void 0) { disabled = false; }
    var className = [spriteName].concat(disabled ? ['disabled'] : []).join(' ');
    return className ? React.createElement("div", { className: className }) : false;
};
var Essence = function (_a) {
    var essence = _a.essence, _b = _a.showTooltip, showTooltip = _b === void 0 ? true : _b, maxItemLevel = _a.maxItemLevel;
    if (!essence) {
        return React.createElement(React.Fragment, null);
    }
    var isDisabled = essence.ItemLevelRestriction != 0 && essence.ItemLevelRestriction < maxItemLevel;
    var tooltipText = isDisabled ? [
        essence.Name,
        "Restricted to item level: " + essence.ItemLevelRestriction
    ] : essence.Name;
    return (React.createElement(Tooltip, { text: tooltipText, disable: !showTooltip }, getImage(essence.Icon, isDisabled)));
};
var mapStateToProps = function (state) { return ({
    maxItemLevel: selectWith(state.old).getMaxItemLevel(),
}); };
export default connect(mapStateToProps)(Essence);
