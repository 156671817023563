import * as React from "react";
import { selectWith } from "../store/query/selectors";
import { connect } from "react-redux";
import { setShaperTag, setElderTag } from '../store/query/actions';
import { addMod, setSelectedItem } from "../store/selections/actions";
import { selectWith as select } from "../store/selections/selectors";
import SearchBar from "./Search/SearchBar";
import BaseItem from "../component/BaseItem/BaseItem";
import { selectors } from '../store/data/selectors';
import { List } from "immutable";
var ItemSelector = function (_a) {
    var setSelectedItem = _a.setSelectedItem, selectedItem = _a.selectedItem, addMod = _a.addMod, setShaperTag = _a.setShaperTag, setElderTag = _a.setElderTag, getItemImplicits = _a.getItemImplicits, itemsByClass = _a.itemsByClass;
    var _b = React.useState(''), query = _b[0], setQuery = _b[1];
    var _c = React.useState(true), showSearch = _c[0], setShowSearch = _c[1];
    var searchInputRef = React.createRef();
    var onClick = function (itemClass) {
        var first = (itemsByClass.get(itemClass) || List())
            .first({ tagShaper: '', tagElder: '' });
        setSelectedItem(null);
        setShaperTag(first.tagShaper);
        setElderTag(first.tagElder);
    };
    var selectItem = function (item) {
        setSelectedItem(item);
    };
    var contains = function (item, query) {
        var q = query.toLowerCase().split(' ');
        var test = function (part) { return (item.name.toLowerCase().indexOf(part) != -1 ||
            item.class.toLowerCase().indexOf(part) != -1); };
        return q.reduce(function (acc, part) { return test(part) ? (acc + 1) : acc; }, 0) == q.length;
    };
    var filtered = itemsByClass
        .map(function (items) { return items.filter(function (item) { return contains(item, query); }); })
        .filterNot(function (items) { return items.isEmpty(); });
    var searchIcon = (React.createElement("div", { className: "search-icon", onClick: function () { return setShowSearch(true); } },
        React.createElement("svg", { focusable: "false", "data-prefix": "fas", "data-icon": "search", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" },
            React.createElement("path", { fill: "#fff", d: "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" }))));
    var header = selectedItem ? React.createElement(BaseItem, { item: selectedItem }) : React.createElement(React.Fragment, null);
    var selectedLabel = (React.createElement(React.Fragment, null,
        searchIcon,
        header));
    var searchBar = React.createElement(SearchBar, { searchRef: searchInputRef, showSuggestions: query.length > 0, suggestions: filtered, onSelected: function (i) { selectItem(i); setShowSearch(false); }, onSelectedClass: function (itemClass) { onClick(itemClass); setShowSearch(false); }, onSearchChanged: function (i) { return setQuery(i); }, onBlur: function () { return setShowSearch(false); }, placeholder: 'search for item base...' }, searchIcon);
    var showSearchBar = !selectedItem || showSearch;
    var content = showSearchBar ? searchBar : selectedLabel;
    var searchFocus = function () {
        if (showSearchBar) {
            setShowSearch(true);
            searchInputRef.current.focus();
        }
    };
    return (React.createElement("nav", { className: 'menu', onClick: searchFocus },
        React.createElement("div", { className: "f" }, content)));
};
var mapStateToProps = function (state) { return ({
    getItemImplicits: selectors.getItemImplicits(state),
    selectedItem: select(state).getSelectedItem(true),
    itemsByClass: selectWith(state.old).getItemsByClass(),
}); };
export default connect(mapStateToProps, { setSelectedItem: setSelectedItem, addMod: addMod, setElderTag: setElderTag, setShaperTag: setShaperTag })(ItemSelector);
