import * as React from "react";
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";
import FossilCrafting from "../FossilCrafting/FossilCrafting";
import SelectedMods from "../SelectedMods/SelectedMods";
var styles = require("./component.css");
var CraftingSection = function (_a) {
    var item = _a.item, implicits = _a.implicits, prefixes = _a.prefixes, suffixes = _a.suffixes;
    var _b = React.useState(true), show = _b[0], setShow = _b[1];
    var _c = React.useState("Mods"), tab = _c[0], setTab = _c[1];
    var wrappingClass = show ? "toolbar-position active" : "toolbar-position";
    var tabContent = {
        "Mods": (React.createElement("section", null,
            React.createElement(SelectedMods, null))),
        "Fossil": (React.createElement("section", null,
            React.createElement(FossilCrafting, null))),
        "Essence": (React.createElement("section", null, "Not Implemented")),
        "Export": (React.createElement("section", null,
            React.createElement("div", { className: "toolbar-setting" },
                React.createElement("div", { className: "spaced_row" },
                    React.createElement(ClipboardCopy, null)))))
    };
    return (React.createElement("div", { className: wrappingClass },
        React.createElement("div", { className: "container", id: styles.container },
            React.createElement("nav", null, Object.keys(tabContent).map(function (tabKey) {
                return React.createElement(React.Fragment, null,
                    React.createElement("input", { id: "tab_" + tabKey, type: "radio", name: "tabs", onChange: function () { return setTab(tabKey); }, checked: tab == tabKey }),
                    React.createElement("label", { htmlFor: "tab_" + tabKey }, tabKey));
            })),
            tabContent[tab])));
};
export default CraftingSection;
