var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_IMPLICITS, SET_EXPLICITS, SET_FOSSIL_DESCRIPTIONS, SET_FOSSILS } from "./types";
import { Map } from 'immutable';
var initialState = {
    implicits: Map(),
    explicits: Map(),
    fossils: [],
    fossilDescriptions: []
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_IMPLICITS:
            var implicits_1 = state.implicits;
            action.mods.forEach(function (mod) {
                implicits_1 = implicits_1.setIn([mod.domain, mod.id], mod);
            });
            return __assign(__assign({}, state), { implicits: implicits_1 });
        case SET_EXPLICITS:
            var explicits_1 = state.explicits;
            action.mods.forEach(function (mod) {
                explicits_1 = explicits_1.setIn([mod.domain, mod.id], mod);
            });
            return __assign(__assign({}, state), { explicits: explicits_1 });
        case SET_FOSSILS:
            return __assign(__assign({}, state), { fossils: action.payload });
        case SET_FOSSIL_DESCRIPTIONS:
            return __assign(__assign({}, state), { fossilDescriptions: action.payload });
        default:
            return state;
    }
}
