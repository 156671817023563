export var ModDomain;
(function (ModDomain) {
    ModDomain["item"] = "ITEM";
    ModDomain["crafted"] = "CRAFTED";
    ModDomain["jewel"] = "JEWEL";
    ModDomain["abyss_jewel"] = "ABYSS_JEWEL";
    ModDomain["delve"] = "DELVE";
    ModDomain["implicits"] = "IMPLICITS";
    ModDomain["elder"] = "ELDER";
    ModDomain["shaper"] = "SHAPER";
    ModDomain["crusader"] = "CRUSADER";
    ModDomain["eyrie"] = "EYRIE";
    ModDomain["basilisk"] = "BASILISK";
    ModDomain["adjudicator"] = "ADJUDICATOR";
    ModDomain["essence"] = "ESSENCE";
})(ModDomain || (ModDomain = {}));
export var DamageType;
(function (DamageType) {
    DamageType[DamageType["physical"] = 1] = "physical";
    DamageType[DamageType["fire"] = 2] = "fire";
    DamageType[DamageType["cold"] = 4] = "cold";
    DamageType[DamageType["lightning"] = 8] = "lightning";
    DamageType[DamageType["chaos"] = 16] = "chaos";
    DamageType[DamageType["elemental"] = 14] = "elemental";
    DamageType[DamageType["total"] = 31] = "total";
})(DamageType || (DamageType = {}));
