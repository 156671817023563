import * as React from "react";
import { connect } from "react-redux";
import { toggleToolbar } from "../../store/query/actions";
var styles = require("./component.css");
var SettingsIcon = function (_a) {
    var toggleToolbar = _a.toggleToolbar;
    var cogwheelSvg = React.createElement("svg", { className: styles.svg, height: '25px', width: '25px', fill: "#000000", xmlns: "http://www.w3.org/2000/svg", version: "1.1", x: "0px", y: "0px", viewBox: "0 0 128 128", "enable-background": "new 0 0 128 128" },
        React.createElement("path", { d: "M11.3,48H8c-3.3,0-6,2.9-6,6.2v18C2,75.6,4.7,78,8,78h3.4c5.4,0,8.7,7.9,4.8,11.8L14,92c-2.4,2.3-2.4,6.2,0,8.5l12.6,12.7  c2.3,2.3,6.1,2.3,8.5,0l3.7-3.7c3.8-3.8,10.2-1.1,10.2,4.3v5.4c0,3.3,2.7,5.8,6,5.8h18c3.3,0,6-2.4,6-5.8v-5.4c0-5.3,6.5-8,10.2-4.2  l3.7,3.7c2.3,2.3,6.1,2.3,8.5,0l12.6-12.6c2.4-2.4,2.3-6.2,0-8.5l-2.2-2.3c-3.9-3.8-0.6-11.8,4.8-11.8h3.4c3.3,0,6-2.4,6-5.8v-18  c0-3.3-2.7-6.2-6-6.2h-3.3c-5.4,0-8.8-8.1-5-11.9l2.4-2.3c2.3-2.3,2.3-6.1,0-8.4l-12.6-12.6c-2.4-2.4-6.2-2.3-8.5,0L90.6,15  C86.8,18.9,79,15.7,79,10.2v-3C79,3.9,76.3,1,73,1H55c-3.3,0-6,2.9-6,6.2v3c0,5.5-7.8,8.7-11.6,4.8l-2.3-2.3c-2.3-2.4-6.2-2.4-8.5,0  L13.9,25.4c-2.3,2.3-2.3,6.1,0,8.5l2.4,2.2C20.1,39.9,16.7,48,11.3,48z M64.2,39.2c13.3,0,24,10.7,24,24s-10.7,24-24,24  s-24-10.7-24-24S51,39.2,64.2,39.2z" }));
    return (React.createElement("div", { className: styles.icon, onClick: function () { return toggleToolbar(); } }, cogwheelSvg));
};
export default connect(null, { toggleToolbar: toggleToolbar })(SettingsIcon);
