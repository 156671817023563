var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ModDomain } from '../../../types';
import { SET_EXTRA_TAGS } from '../query/types';
import { setPossibleItemMods } from '../selections/actions';
import { getItemTags, weightMultiplier, Reductions } from '../../common';
import { ADD_SELECTED_FOSSIL, REMOVE_SELECTED_FOSSIL } from '../selections/types';
var getItemDomain = function (tags) {
    if (tags.includes("abyss_jewel")) {
        return ModDomain.abyss_jewel;
    }
    else if (tags.includes("jewel")) {
        return ModDomain.jewel;
    }
    return ModDomain.item;
};
var triggeringActions = [SET_EXTRA_TAGS, ADD_SELECTED_FOSSIL, REMOVE_SELECTED_FOSSIL];
export default (function (store) { return function (next) { return function (action) {
    var result = next(action);
    var state = store.getState();
    var extraTags = state.old.extraTags || [];
    if (!triggeringActions.includes(action.type) || !state.selections.selectedItem) {
        return result;
    }
    // TODO: store this?
    var item = state.selections.selectedItem;
    var positiveFossilWeights = state.selections.fossils
        .reduce(function (weights, fossil) { return weights.concat(fossil.tagWeights); }, []);
    var negativeFossilWeights = state.selections.fossils
        .reduce(function (weights, fossil) { return weights.concat(fossil.negativeTagWeights); }, []);
    var addedDelveModsIds = state.selections.fossils.reduce(function (modIds, fossil) { return modIds.concat(fossil.addedMods); }, []);
    var getFossilWeightMultiplier = function (mod, weights, transform) {
        var applicableWeights = weights.filter(function (w) { return mod.modTypeTags.includes(w.tag); });
        if (applicableWeights.length === 0) {
            return 1;
        }
        return applicableWeights.map(function (it) { return transform(it.weight); }).reduce(Reductions.product, 1);
    };
    var implicitMods = state.data.implicits.get(getItemDomain(item.tags));
    var implicitIds = (state.selections.selectedImplicits || [])[0];
    var itemImplicitMods = implicitIds.map(function (modId) { return implicitMods.get(modId); }).filter(function (it) { return it !== undefined; });
    var implictTags = itemImplicitMods.reduce(function (acc, mod) { return acc.concat(mod.tags); }, []);
    var tags = getItemTags(item, state.selections.itemInfluence).concat(implictTags).concat(extraTags);
    var updatedMods = state.selections.possibleItemMods.mapEntries(function (_a) {
        var domain = _a[0], group = _a[1];
        var groupData = group.map(function (affixType) {
            return affixType.map(function (mods) {
                return mods.map(function (mod) {
                    var weightModifier = mod.generationWeights
                        .filter(function (w) { return ['default'].concat(extraTags).includes(w.tag); })[0] || { tag: 'unmodified', weight: 100 };
                    var includeDelve = ModDomain.delve.toString() !== domain || addedDelveModsIds.includes(mod.id) ? 1 : 0;
                    var positiveFossilMulti = getFossilWeightMultiplier(mod, positiveFossilWeights, function (w) { return w / 100; });
                    var negativeFossilMulti = getFossilWeightMultiplier(mod, negativeFossilWeights, function (w) { return w / 100; });
                    var weightMulti = weightMultiplier(weightModifier) * positiveFossilMulti * negativeFossilMulti;
                    var baseWeight = mod.spawnWeights.filter(function (w) { return tags.includes(w.tag); })[0] || { tag: 'unspawnable', weight: 0 };
                    var modifiedWeight = baseWeight && __assign(__assign({}, baseWeight), { weight: baseWeight.weight * weightMulti * includeDelve });
                    return __assign(__assign({}, mod), { modifiedWeight: modifiedWeight });
                });
            });
        });
        return [domain, groupData];
    });
    setTimeout(function () { return store.dispatch(setPossibleItemMods(updatedMods)); }, 0);
    return result;
}; }; });
