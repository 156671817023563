import { ModDomain } from "../../../types";
import { Reductions } from "../../common";
import { Map } from 'immutable';
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import { toIncreaseMultiplier } from "../../gameMechanics";
var idsToMod = function (state, modIds, includeCrafted) {
    if (includeCrafted === void 0) { includeCrafted = false; }
    // TODO: flatten all mod types and index by id
    var craftedDomain = includeCrafted ? [ModDomain.crafted] : [];
    var itemMods = [ModDomain.abyss_jewel, ModDomain.delve, ModDomain.item, ModDomain.jewel, ModDomain.implicits].concat(craftedDomain)
        .reduce(function (acc, domain) { return acc.concat(state.old.itemMods[domain]); }, []);
    return modIds
        .map(function (modId) { return itemMods.find(function (x) { return x.id === modId; }); })
        .filter(function (mod) { return mod !== undefined; });
};
export function selectWith(state) {
    return {
        getSelectedPrefixes: function () {
            var groups = state.selections.selectedPrefix || [];
            return groups.map(function (group) { return idsToMod(state, group, true); });
        },
        getSelectedSuffixes: function () {
            var groups = state.selections.selectedSuffix || [];
            return groups.map(function (group) { return idsToMod(state, group, true); });
        },
        getSelectedImplicits: function () {
            var groups = state.selections.selectedImplicits || [];
            return groups.map(function (group) { return idsToMod(state, group, false); });
        },
        getAllSelectedMods: function () {
            var implicit = state.selections.selectedImplicits || [];
            var prefix = state.selections.selectedPrefix || [];
            var suffix = state.selections.selectedSuffix || [];
            var combined = implicit.concat(prefix).concat(suffix);
            var output = combined.reduce(function (acc, group) { return acc.concat(idsToMod(state, group, false)); }, []);
            return output;
        },
        getAllSelectedExplicits: function () {
            var prefix = state.selections.selectedPrefix || [];
            var suffix = state.selections.selectedSuffix || [];
            var combined = prefix.concat(suffix);
            var output = combined.reduce(function (acc, group) { return acc.concat(idsToMod(state, group, false)); }, []);
            return output;
        },
        getAllSelectedImplicits: function () {
            var implicit = state.selections.selectedImplicits || [];
            return implicit.reduce(function (acc, group) { return acc.concat(idsToMod(state, group, false)); }, []);
        },
        getSelectedPrefixGroups: function () {
            var groups = state.selections.selectedPrefix;
            return groups.map(function (group) { return idsToMod(state, group).map(function (x) { return x.group; }); });
        },
        getSelectedSuffixGroups: function () {
            var groups = state.selections.selectedSuffix;
            return groups.map(function (group) { return idsToMod(state, group).map(function (x) { return x.group; }); });
        },
        getSelectedImplicitGroups: function () {
            var groups = state.selections.selectedImplicits;
            return groups.map(function (group) { return idsToMod(state, group).map(function (x) { return x.group; }); });
        },
        getSelectedFossils: function () { return state.selections.fossils; },
        getAddedFossilModIds: function () { return state.selections.fossils.reduce(function (modIds, fossil) { return modIds.concat(fossil.addedMods); }, []); },
        getPossibleItemMods: function () { return state.selections.possibleItemMods; },
        // selected groups with combined weights (selected mod and higher)
        getWeightsOfSelectedModGroups: function () {
            var possibleMods = state.selections.possibleItemMods;
            var groups = state.selections.selectedPrefix.concat(state.selections.selectedSuffix); // TODO: this isn't pretty
            var modByModKey = groups
                .map(function (group) { return idsToMod(state, group); })
                .reduce(Reductions.flatten)
                .reduce(function (acc, item) { return acc.set(item.modTypeKey, item); }, Map());
            return modByModKey.valueSeq().toArray().reduce(function (total, value) {
                var allMods = possibleMods.reduce(function (map, affixGroup) { return map.mergeDeep(affixGroup); }, Map()); // TODO: this is a test
                var wantedMods = (allMods.getIn([value.type, value.modTypeKey]) || [])
                    .filter(function (mod) { return mod.ilvl >= value.ilvl; })
                    .reduce(function (acc, mod) {
                    var itemWeight = (mod.modifiedWeight || { weight: 0 }).weight;
                    var currentWeight = acc.get(mod.group) || 0;
                    return acc.set(mod.group, currentWeight + itemWeight);
                }, Map());
                return total.mergeWith(function (prev, next, key) { return (prev || 0) + (next || 0); }, wantedMods);
            }, Map());
        },
        getWeightsOfSelectedImplicitGroups: function () {
            var possibleMods = state.selections.possibleItemMods;
            var groups = state.selections.selectedImplicits;
            var modByModKey = groups
                .map(function (group) { return idsToMod(state, group); })
                .reduce(Reductions.flatten)
                .reduce(function (acc, item) { return acc.set(item.modTypeKey, item); }, Map());
            return modByModKey.valueSeq().toArray().reduce(function (total, value) {
                var wantedMods = (possibleMods.getIn(['BASE', value.type, value.modTypeKey]) || [])
                    .filter(function (mod) { return mod.ilvl >= value.ilvl; })
                    .reduce(function (acc, mod) {
                    var itemWeight = (mod.modifiedWeight || { weight: 0 }).weight;
                    var currentWeight = acc.get(mod.group) || 0;
                    return acc.set(mod.group, currentWeight + itemWeight);
                }, Map());
                return total.mergeWith(function (prev, next, key) { return (prev || 0) + (next || 0); }, wantedMods);
            }, Map());
        },
        // TODO: move essenceByModId to data reducer
        getEssenceForModId: function (modId) {
            var essenceMappings = state.old.essenceMappingByModId[modId] || [];
            var foundMappings = essenceMappings.filter(function (mapping) { return difference(mapping.tags, state.selections.selectedItem.tags).length == 0; });
            var mapping = foundMappings.length > 0 && foundMappings[0];
            return mapping.essenceId && state.old.essenceByModId[mapping.essenceId];
        },
        getSelectedWeaponBase: function () {
            if (!state.selections.selectedItem)
                return;
            return state.selections.selectedItem.weapon;
        },
        getItemInfluence: function () { return state.selections.itemInfluence; },
        getSelectedItem: function (applyMods) {
            if (applyMods === void 0) { applyMods = false; }
            if (!state.selections.selectedItem)
                return;
            var item = JSON.parse(JSON.stringify(state.selections.selectedItem));
            if (applyMods) {
                var stats_1 = flatten(state.old.selectedMods.map(function (mod) { return mod.stats; }));
                var getValue_1 = function (rolls, range) {
                    return Math.round(range.min + ((range.max - range.min) * rolls));
                };
                var getCombinedStats_1 = function (keys) {
                    var range = stats_1.filter(function (stat) { return keys.includes(stat.name); })
                        .reduce(function (dmg, stat) {
                        dmg.min = dmg.min + stat.min;
                        dmg.max = dmg.max + stat.max;
                        return dmg;
                    }, { min: 0, max: 0 });
                    return getValue_1(state.old.luckiness, range);
                };
                var getCombinedStat = function (key) {
                    return getCombinedStats_1([key]);
                };
                if (item.weapon) {
                    var base = item.weapon;
                    var additionalPhysMin = getCombinedStat("local_minimum_added_physical_damage");
                    var additionalPhysMax = getCombinedStat("local_maximum_added_physical_damage");
                    var quality = getCombinedStat("local_item_quality_+") + 20;
                    var localPhysIncrease = getCombinedStat("local_physical_damage_+%") + quality;
                    var localAttackSpeedIncrease = getCombinedStat("local_attack_speed_+%");
                    base.aps = base.aps * toIncreaseMultiplier(localAttackSpeedIncrease);
                    var incCritChance = getCombinedStat("local_critical_strike_chance_+%");
                    base.critical = base.critical * toIncreaseMultiplier(incCritChance);
                    base.min = (base.min + additionalPhysMin) * toIncreaseMultiplier(localPhysIncrease);
                    base.max = (base.max + additionalPhysMax) * toIncreaseMultiplier(localPhysIncrease);
                    item.quality = quality;
                }
                if (item.armour) {
                    var base = item.armour;
                    var quality = getCombinedStat("local_item_quality_+") + 20;
                    var incAR = getCombinedStats_1([
                        "local_physical_damage_reduction_rating_+%",
                        "local_armour_and_evasion_+%",
                        "local_armour_and_energy_shield_+%",
                        "local_armour_and_evasion_and_energy_shield_+%"
                    ]) + quality;
                    var incEV = getCombinedStats_1([
                        "local_evasion_rating_+%",
                        "local_armour_and_evasion_+%",
                        "local_evasion_and_energy_shield_+%",
                        "local_armour_and_evasion_and_energy_shield_+%"
                    ]) + quality;
                    var incES = getCombinedStats_1([
                        "local_energy_shield_+%",
                        "local_armour_and_energy_shield_+%",
                        "local_evasion_and_energy_shield_+%",
                        "local_armour_and_evasion_and_energy_shield_+%"
                    ]) + quality;
                    var flatAR = getCombinedStat("local_base_physical_damage_reduction_rating");
                    var flatEV = getCombinedStat("local_base_evasion_rating");
                    var flatES = getCombinedStat("local_energy_shield");
                    base.armour = (base.armour + flatAR) * (1 + incAR / 100.0);
                    base.evasion = (base.evasion + flatEV) * (1 + incEV / 100.0);
                    base.energy = (base.energy + flatES) * (1 + incES / 100.0);
                    item.quality = quality;
                }
            }
            return item;
        },
    };
}
