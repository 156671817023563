import React from 'react';
import { DamageType } from "../../../types";
import { connect } from 'react-redux';
import { selectWith } from '../../store/query/selectors';
import { selectors } from '../../store/data/selectors';
import { setSelectedItem } from "../../store/selections/actions";
import { getDps } from '../../gameMechanics';
import { Reductions } from '../../common';
var styles = require("./component.css");
;
var BaseItems = function (_a) {
    var items = _a.items, getItemImplicits = _a.getItemImplicits, setSelectedItem = _a.setSelectedItem;
    if (!items) {
        return React.createElement(React.Fragment, null);
    }
    var renderOptionRow = function (item, desc) {
        if (!item || item.name.length == 0) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: styles.optionsRow, onClick: function () { return setSelectedItem(item); } },
            React.createElement("span", null, item.name),
            React.createElement("span", null, desc)));
    };
    var bestWeapon = function (items) {
        var weapons = items.filter(function (item) { return !!item.weapon; });
        if (weapons.isEmpty()) {
            return React.createElement(React.Fragment, null);
        }
        var itemsOrderedByDps = weapons.map(function (item) { return ({ dps: getDps(item.weapon, DamageType.total), item: item }); })
            .sort(function (a, b) { return b.dps - a.dps; })
            .map(function (itemDps) { return itemDps.item; });
        var bestDps = itemsOrderedByDps.first({ name: '' });
        var bestAps = itemsOrderedByDps.reduce(function (best, item) { return item.weapon.aps > best.weapon.aps ? item : best; });
        var options = (bestDps.name == bestAps.name) ? renderOptionRow(bestDps, 'DPS / APS') : (React.createElement(React.Fragment, null,
            renderOptionRow(bestDps, 'DPS'),
            renderOptionRow(bestAps, 'APS')));
        return (React.createElement("div", { className: styles.options }, options));
    };
    var bestImplicits = function (items) {
        var itemWithImplicits = items
            .filter(function (item) { return item.implicitMods && item.implicitMods.length > 0; })
            .map(function (item) { return ({ item: item, implicit: getItemImplicits(item)[0] }); }); // needs to change if GGG creates base with multiple implicits
        if (itemWithImplicits.isEmpty()) {
            return React.createElement(React.Fragment, null);
        }
        var grouped = itemWithImplicits
            .groupBy(function (it) { return it.implicit && it.implicit.modTypeKey; })
            .toMap();
        return (React.createElement("div", { className: styles.options }, grouped.mapEntries(function (_a) {
            var modTypeKey = _a[0], value = _a[1];
            var first = value.first({ item: undefined, implicit: undefined });
            return [modTypeKey, renderOptionRow(first.item, first.implicit.translation[0])];
        }).valueSeq()));
    };
    var bestArmor = function (items) {
        var armours = items.filter(function (item) { return !!item.armour; });
        if (armours.isEmpty()) {
            return React.createElement(React.Fragment, null);
        }
        var best = function (a, b, valueFunc) {
            var aValues = valueFunc(a);
            var bValues = valueFunc(b);
            if (aValues.includes(0) && bValues.includes(0)) {
                return { name: '', armour: { armour: 0, evasion: 0, energy: 0 } };
            }
            else if (aValues.includes(0)) {
                return b;
            }
            else if (bValues.includes(0)) {
                return a;
            }
            return aValues.reduce(Reductions.sum) > bValues.reduce(Reductions.sum) ? a : b;
        };
        var bestAR = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.armour]; }); });
        var bestEvasion = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.evasion]; }); });
        var bestEnergyShield = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.energy]; }); });
        var bestAR_EV = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.armour, item.armour.evasion]; }); });
        var bestAR_ES = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.armour, item.armour.energy]; }); });
        var bestEV_ES = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.evasion, item.armour.energy]; }); });
        var bestHybrid = armours.reduce(function (prev, item) { return best(item, prev, function (item) { return [item.armour.armour, item.armour.evasion, item.armour.energy]; }); });
        return (React.createElement("div", { className: styles.options },
            renderOptionRow(bestAR, 'AR'),
            renderOptionRow(bestEvasion, 'EV'),
            renderOptionRow(bestEnergyShield, 'ES'),
            renderOptionRow(bestAR_EV, 'AR/EV'),
            renderOptionRow(bestAR_ES, 'AR/ES'),
            renderOptionRow(bestEV_ES, 'EV/ES'),
            renderOptionRow(bestHybrid, 'AR/EV/ES')));
    };
    var itemClass = function (className, items) {
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.header }, className),
            bestWeapon(items),
            bestArmor(items),
            bestImplicits(items)));
    };
    var components = items.mapEntries(function (_a) {
        var key = _a[0], value = _a[1];
        return [key, itemClass(key, value.valueSeq())];
    }).valueSeq();
    var halfIndex = Math.floor(components.size / 2);
    var firstHalf = components.slice(0, halfIndex);
    var secondHalf = components.slice(halfIndex, components.size);
    return (React.createElement("div", { className: "row row_padded" },
        React.createElement("div", { className: "six columns" }, firstHalf),
        React.createElement("div", { className: "six columns" }, secondHalf)));
};
var mapStateToProps = function (state) { return ({
    items: selectWith(state.old).getItemsByClass(),
    getItemImplicits: selectors.getItemImplicits(state)
}); };
export default connect(mapStateToProps, { setSelectedItem: setSelectedItem })(BaseItems);
