import * as React from "react";
var styles = require("./component.css");
var GroupSelector = function (_a) {
    var dismiss = _a.dismiss, onSelectGroup = _a.onSelectGroup;
    React.useEffect(function () {
        window.addEventListener("click", dismiss);
        return function () { return window.removeEventListener("click", dismiss); };
    });
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.row },
            React.createElement("span", null, "Select group")),
        React.createElement("div", { className: styles.row },
            React.createElement("div", { className: styles.group1, onClick: function () { return onSelectGroup(0); } }, "\u00A0"),
            React.createElement("div", { className: styles.group2, onClick: function () { return onSelectGroup(1); } }, "\u00A0"),
            React.createElement("div", { className: styles.group3, onClick: function () { return onSelectGroup(2); } }, "\u00A0"))));
};
export default GroupSelector;
