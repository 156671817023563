import { SET_IMPLICITS, SET_EXPLICITS, SET_FOSSIL_DESCRIPTIONS, SET_FOSSILS } from "./types";
export function setImplicitMods(mods) {
    return {
        type: SET_IMPLICITS,
        mods: mods
    };
}
export function setExplicitMods(mods) {
    return {
        type: SET_EXPLICITS,
        mods: mods
    };
}
export function setFossils(fossils) {
    return {
        type: SET_FOSSILS,
        payload: fossils
    };
}
export function setFossilDescriptions(descriptions) {
    return {
        type: SET_FOSSIL_DESCRIPTIONS,
        payload: descriptions
    };
}
