import React from 'react';
import { DamageType } from "../../../types";
import { connect } from 'react-redux';
import { selectWith } from '../../store/query/selectors';
import { selectWith as select } from '../../store/selections/selectors';
import { getDps } from '../../gameMechanics';
var styles = require("./component.css");
export var BaseItemView;
(function (BaseItemView) {
    BaseItemView[BaseItemView["ITEM_STATS"] = 0] = "ITEM_STATS";
    BaseItemView[BaseItemView["DPS"] = 1] = "DPS";
})(BaseItemView || (BaseItemView = {}));
;
var NOOP = function (e) { };
var toFixed = function (value) {
    return Math.round(value * 100) / 100;
};
var formatDps = function (dps, suffix) { return !dps ? "0 " + suffix : dps.toFixed(0) + " " + suffix; };
var getEnabledViews = function (item) {
    var dpsView = item.weapon ? BaseItemView.DPS : null;
    var itemStatsView = (item.weapon || item.armour) ? BaseItemView.ITEM_STATS : null;
    return [itemStatsView, dpsView].filter(function (it) { return it !== null; });
};
var BaseItem = function (_a) {
    var item = _a.item, _b = _a.onClick, onClick = _b === void 0 ? NOOP : _b, luckiness = _a.luckiness, selectedMods = _a.selectedMods, weaponBase = _a.weaponBase;
    var _c = React.useState(0), currentViewIndex = _c[0], setViewIndex = _c[1];
    if (!item) {
        return React.createElement(React.Fragment, null);
    }
    var enabledViews = getEnabledViews(item);
    var handleClick = function (event) {
        var nextIndex = currentViewIndex + 1;
        if (nextIndex >= enabledViews.length) {
            nextIndex = 0;
        }
        setViewIndex(nextIndex);
        onClick(event);
    };
    var getImage = function (className) { return (className ? React.createElement("div", { className: styles.image },
        React.createElement("div", { className: className })) : false); };
    var getWeaponStats = function (stats) {
        if (!stats) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, Math.round(stats.min) + "-" + Math.round(stats.max) + " DMG"),
            React.createElement("div", null, toFixed(stats.critical) + "% CRIT"),
            React.createElement("div", null, toFixed(stats.aps) + " APS")));
    };
    var getArmourStats = function (stats) {
        if (!stats) {
            return React.createElement(React.Fragment, null);
        }
        var formatStat = function (value, suffix) { return value == 0 ? '' : value.toFixed(0) + " " + suffix; };
        var formattedStats = [
            formatStat(stats.armour, 'AR'),
            formatStat(stats.evasion, 'EV'),
            formatStat(stats.energy, 'ES')
        ].filter(function (stat) { return stat.length; }).join(" / ");
        return (React.createElement(React.Fragment, null, formattedStats));
    };
    var topRightItem = '';
    var bottomRow = React.createElement(React.Fragment, null);
    if (BaseItemView.DPS == enabledViews[currentViewIndex]) {
        var totalDps = getDps(weaponBase, DamageType.total, selectedMods, luckiness);
        var physicalDps = getDps(weaponBase, DamageType.physical, selectedMods, luckiness);
        var elementalDps = getDps(weaponBase, DamageType.elemental, selectedMods, luckiness);
        var chaosDps = getDps(weaponBase, DamageType.chaos, selectedMods, luckiness);
        topRightItem = formatDps(totalDps, 'DPS');
        bottomRow = (React.createElement("div", null,
            React.createElement("div", null, formatDps(physicalDps, 'pDPS')),
            React.createElement("div", null, formatDps(elementalDps, 'eDPS')),
            React.createElement("div", null, formatDps(chaosDps, 'cDPS'))));
    }
    else if (BaseItemView.ITEM_STATS == enabledViews[currentViewIndex]) {
        topRightItem = item.quality && item.quality + "%";
        bottomRow = (React.createElement("div", null,
            getWeaponStats(item.weapon),
            getArmourStats(item.armour)));
    }
    else {
        topRightItem = item.quality && item.quality + "%";
        bottomRow = (React.createElement("div", null,
            getWeaponStats(item.weapon),
            getArmourStats(item.armour)));
    }
    return (React.createElement("div", { className: styles.container, onClick: handleClick },
        getImage(item.icon),
        React.createElement("div", { className: styles.stats },
            React.createElement("div", null,
                React.createElement("div", { className: styles.name }, item.name),
                React.createElement("div", null, topRightItem)),
            bottomRow)));
};
var mapStateToProps = function (state) { return ({
    weaponBase: select(state).getSelectedWeaponBase(),
    luckiness: selectWith(state.old).getLuckiness(),
    selectedMods: select(state).getAllSelectedMods(),
}); };
export default connect(mapStateToProps)(BaseItem);
